import React, { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import { putCommissionData } from "services/endpoints/users";

const Commission = ({ id, percent }) => {
  const [commission, setCommission] = useState(80);

  useEffect(() => {
    percent && setCommission(percent);
  }, [percent]);

  const { addToast } = useToasts();

  const updateCommission = (data) => setCommission(data);

  const postNewCommission = async () => {
    if (commission > 100 || commission < 1) {
      addToast("Commission must be between 1 and 100", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000});
    } else {
      const { error } = await putCommissionData(id, commission);
      if (error) {
        addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000});
      } else {
        addToast("Commission updated", { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000});
      }
    }
  };

  return (
    <Card>
      <CardHeader color="primary">Commision</CardHeader>
      <CardBody>
        <CustomInput
          labelText="Change %"
          id="commision-percentage"
          formControlProps={{ fullWidth: true }}
          val={`${commission}`}
          updateState={updateCommission}
        />
        <Button color="success" onClick={postNewCommission}>
          Update
        </Button>
      </CardBody>
    </Card>
  );
};

export default Commission;
