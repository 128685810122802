import React, { useState } from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/usersStyle";
import { useToasts } from "react-toast-notifications";
import { configDateId, calcAge } from "helpers";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import FaceImg from "assets/img/face_not_uploaded.png";
import IDImg from "assets/img/id_not_uploaded.png";

import {
  postApproveProfile,
  postRejectProfile,
} from "services/endpoints/users";

const ImageVerification = ({ id, verified, setVerified, profileImages }) => {
  const [rejected, setRejected] = useState(false);
  const [confirmRejection, setConfirmRejection] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");

  const classes = useStyles();
  const { addToast } = useToasts();

  const approveProfile = async () => {
    setConfirmRejection(false);
    setRejectionReason("");
    const { data, error } = await postApproveProfile(id);
    if (data) {
      addToast("User VERIFIED successfully", { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000});
      setVerified(true);
    }
    if (error) {
      addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000});
    }
  };

  const rejectProfile = async () => {
    if (rejectionReason.length > 0) {
      setConfirmRejection(false);
      setRejectionReason("");

      //currently
      setRejected(true);

      const { data, error } = await postRejectProfile(id, rejectionReason);
      if (data) {
        setVerified(false);
        setRejected(true);
        addToast("User REJECTED successfully", { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000});
      }
      if (error) {
        addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000});
      }
    } else {
      addToast("You neeed to write a reason", { appearance: "warning" });
    }
  };

  const updateRejectionReason = (data) => setRejectionReason(data);

  return (
    <>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitlePrimary}>Verify profile</h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <img
                className={classes.profileImage}
                src={profileImages.face_image_url || FaceImg}
                alt="Face"
                onClick={() =>
                  profileImages.face_image_url &&
                  window.open(profileImages.face_image_url, "_blank")
                }
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <img
                className={classes.profileImage}
                src={profileImages.id_image_url || IDImg}
                alt="id"
                onClick={() =>
                  profileImages.id_image_url &&
                  window.open(profileImages.id_image_url, "_blank")
                }
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <img
                className={classes.profileImage}
                src={profileImages.proof_image_url || IDImg}
                alt="proof"
                onClick={() =>
                  profileImages.proof_image_url &&
                  window.open(profileImages.proof_image_url, "_blank")
                }
              />
            </GridItem>
          </GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {verified ? (
              <CardHeader color="success">
                VERIFIED
                <span style={{ paddingLeft: "1rem" }}>
                  {profileImages.birthday
                    ? `[DOB - ${configDateId(
                        profileImages.birthday
                      )} (Age: ${calcAge(profileImages.birthday)})]`
                    : "[DOB not submitted]"}
                </span>
                <Button
                  color="warning"
                  style={{
                    float: "right",
                    padding: "4px 12px",
                    bottom: "6px",
                  }}
                  onClick={() => setConfirmRejection(!confirmRejection)}
                >
                  Remove verification
                </Button>
              </CardHeader>
            ) : rejected ? (
              <CardHeader color="primary">REJECTED</CardHeader>
            ) : (
              <>
                <Button color="success" onClick={approveProfile}>
                  Approve
                </Button>
                <Button
                  color="primary"
                  onClick={() => setConfirmRejection(true)}
                >
                  Reject
                </Button>
                <span style={{ paddingLeft: "1rem" }}>
                  {profileImages.birthday
                    ? `[DOB - ${configDateId(
                        profileImages.birthday
                      )} (Age: ${calcAge(profileImages.birthday)})]`
                    : "[DOB not submitted]"}
                </span>
              </>
            )}
          </GridItem>
          {confirmRejection && (
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText={
                  verified
                    ? "Reason for removing verification"
                    : "Reason for rejection"
                }
                id="rejection-reason"
                formControlProps={{ fullWidth: true }}
                val={`${rejectionReason}`}
                updateState={updateRejectionReason}
              />
              <Button
                color="primary"
                onClick={() => setConfirmRejection(false)}
              >
                Cancel
              </Button>
              <Button color="success" onClick={rejectProfile}>
                Confirm
              </Button>
            </GridItem>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default ImageVerification;
