import React, { useState } from "react";
import Tabs from "../../components/CustomTabs/CustomTabs.js";
import UsersTable from "./UsersTable";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import LinkedCameraIcon from "@material-ui/icons/LinkedCamera";
import People from "@material-ui/icons/People";
import Person from "@material-ui/icons/Person";

export const Users = () => {

  return (
    <Tabs
      title="Users:"
      headerColor="rose"
      tabs={[
        {
          tabName: "To Be Verified",
          tabIcon: VerifiedUser,
          tabContent: (
            <UsersTable
              api="users/to-be-verified-users"
              search="Find user"
              header="Users"
              tabletext="Table with users waiting for verification"
            />
          ),
        },
        {
          tabName: "All Users",
          tabIcon: People,
          tabContent: (
            <UsersTable
              api="users"
              search="Find user"
              header="Users"
              tabletext="Table with registered users"
            />
          ),
        },
        {
          tabName: "Creators",
          tabIcon: LinkedCameraIcon,
          tabContent: (
            <UsersTable
              api="creators"
              search="Find creator"
              header="Creators"
              tabletext="Table with registered creators"
            />
          ),
        },
      ]}
    />
  );
};

export const ToBeVerified = () => {
  return (
    <Tabs
      title="Users:"
      headerColor="rose"
      tabs={[
        {
          tabName: "To Be Verified",
          tabIcon: VerifiedUser,
          tabContent: (
            <UsersTable
              api="users/to-be-verified-users"
              search="Find user"
              header="Users"
              tabletext="Table with users waiting for verification"
            />
          ),
        },
      ]}
    />
  );
};

export const AllUsers = () => {

  return (
    <Tabs
      title="Users:"
      headerColor="rose"
      tabs={[
        {
          tabName: "All Users",
          tabIcon: People,
          tabContent: (
            <UsersTable
              api="users"
              header="Users"
              tabletext="Table with registered users"
            />
          ),
        },
      ]}
    />
  );
};

export const SearchUsers = () => {
  const [searchInput, setSearchInput] = useState('');


  return (
    <Tabs
      title="Search users:"
      headerColor="rose"
      toggleSearch
      setSearchValue={setSearchInput}
      searchValue={searchInput}
      tabs={[
        {
          tabName: "Search Users",
          tabIcon: People,
          tabContent: (
            <UsersTable
              api="users"
              search={searchInput}
              header="Users"
              tabletext="Table with registered users"
            />
          ),
        },
      ]}
    />
  );
};

export const RejectedUsers = () => {
  const [searchInput, setSearchInput] = useState('');

  return (
    <Tabs
      title="Rejected users:"
      headerColor="rose"
      toggleSearch
      setSearchValue={setSearchInput}
      searchValue={searchInput}
      tabs={[
        {
          tabName: "Rejected Users",
          tabIcon: People,
          tabContent: (
            <UsersTable
              api="users/rejected"
              search={searchInput}
              header="Rejected users"
              tabletext="Table with rejected users"
            />
          ),
        },
      ]}
    />
  );
};

export const DeactivatedUsers = () => {
    const [searchInput, setSearchInput] = useState('');

    return (
        <Tabs
            title="Deactivated users:"
            headerColor="rose"
            toggleSearch
            setSearchValue={setSearchInput}
            searchValue={searchInput}
            tabs={[
                {
                    tabName: "Deactivated Users",
                    tabIcon: People,
                    tabContent: (
                        <UsersTable
                            api="users/deactivated"
                            search={searchInput}
                            header="Deactivated users"
                            tabletext="Table with deactivated users"
                        />
                    ),
                },
            ]}
        />
    );
};

export const BannedUsers = () => {
    const [searchInput, setSearchInput] = useState('');

    return (
        <Tabs
            title="Rejected users:"
            headerColor="rose"
            toggleSearch
            setSearchValue={setSearchInput}
            searchValue={searchInput}
            tabs={[
                {
                    tabName: "Banned Users",
                    tabIcon: People,
                    tabContent: (
                        <UsersTable
                            api="users/banned"
                            search={searchInput}
                            header="Banned users"
                            tabletext="Table with banned users"
                        />
                    ),
                },
            ]}
        />
    );
};

export const Creators = () => {
  const [searchInput, setSearchInput] = useState('');

  return (
    <Tabs
      title="Creators:"
      headerColor="rose"
      toggleSearch
      setSearchValue={setSearchInput}
      searchValue={searchInput}
      tabs={[
        {
          tabName: "Creators",
          tabIcon: LinkedCameraIcon,
          tabContent: (
            <UsersTable
              api="creators"
              search={searchInput}
              header="Creators"
              tabletext="Table with registered creators"
            />
          ),
        },
      ]}
    />
  );
};

export const VerifiedEmail = () => {
    const [searchInput, setSearchInput] = useState('');

    return (
        <Tabs
            title="Users"
            headerColor="rose"
            toggleSearch
            setSearchValue={setSearchInput}
            searchValue={searchInput}
            tabs={[
                {
                    tabName: "Users",
                    tabIcon: Person,
                    tabContent: (
                        <UsersTable
                            api="users/verified"
                            search={searchInput}
                            header="Users"
                            tabletext="Email verified accounts"
                        />
                    ),
                },
            ]}
        />
    );
};

export const UnverifiedUsers = () => {
    const [searchInput, setSearchInput] = useState('');

    return (
        <Tabs
            title="Users"
            headerColor="rose"
            toggleSearch
            setSearchValue={setSearchInput}
            searchValue={searchInput}
            tabs={[
                {
                    tabName: "Unverified Users",
                    tabIcon: Person,
                    tabContent: (
                        <UsersTable
                            api="users/unverified"
                            search={searchInput}
                            header="Users"
                            tabletext="Unverified email accounts"
                        />
                    ),
                },
            ]}
        />
    );
};