const months = {
  "01": "Jan",
  "02": "Feb",
  "03": "Mar",
  "04": "Apr",
  "05": "May",
  "06": "Jun",
  "07": "Jul",
  "08": "Aug",
  "09": "Sep",
  "10": "Oct",
  "11": "Nov",
  "12": "Dec",
};

export const configDate = (e) => {
  let x = e.split("T");
  let day = x[0].split("-");
  let configuredDay = `${day[2]} ${months[day[1]]} ${day[0]}`;
  return `${configuredDay}, ${x[1].slice(0, 8)}`;
};

export const configDateSimple = (e) => {
  let x = e.split(" ");
  let day = x[0].split("-");
  let configuredDay = `${day[2]} ${months[day[1]]} ${day[0]}`;
  return `${configuredDay}, ${x[1].slice(0, 8)}`;
};

export const configDateId = (e) => {
  let x = e.split("T");
  let day = x[0].split("-");
  return `${day[2]}/${day[1]}/${day[0]}`;
};

export const calcAge = (e) => {
  let dob = new Date(e);
  let now = new Date();
  return Math.floor((now - dob) / (365 * 1000 * 60 * 60 * 24));
};
