import axiosInstance from "api/axiosInstance";

// USERS TABLE
export const getUsersData = async (page = 1, api, term = '', direction) => {
  const { data } = await axiosInstance({
    method: "get",
    url: `/api/admin/${api}?direction=${direction}&page=${page}&term=${term}`,
  });
  return data;
};

export const searchUsers = async (term = '') => {
  const { data } = await axiosInstance({
    method: 'get',
    url: `/api/admin/users/search?term=${term}`
  });
  return data
}

// PROFILE
export const getProfileData = async (id) => {
  const { data } = await axiosInstance({
    method: "get",
    url: `/api/admin/users/edit/${id}`,
  });
  return data;
};

export const postUpdateProfileData = async (id, info) => {
  const { data } = await axiosInstance({
    method: "post",
    url: `/api/admin/users/update/${id}`,
    data: info,
  });
  return data;
};

export const postApproveProfile = async (id) => {
  const { data } = await axiosInstance({
    method: "post",
    url: `/api/admin/face_id/approve/${id}`,
  });
  return data;
};

export const postRejectProfile = async (id, rejectionReason) => {
  const { data } = await axiosInstance({
    method: "post",
    url: `/api/admin/face_id/reject/${id}`,
    data: { message: rejectionReason },
  });
  return data;
};

export const putCommissionData = async (id, commission) => {
  const { data } = await axiosInstance({
    method: "put",
    url: `/api/admin/creators/${id}/percent`,
    data: { percent: commission },
  });
  console.log(data);
  return data;
};

export const getLoginsData = async (id, page) => {
  const { data } = await axiosInstance({
    method: "get",
    url: `/api/admin/users/${id}/logins?page=${page}`,
  });
  return data;
};

export const getNotesData = async (id, page) => {
  const { data } = await axiosInstance({
    method: "get",
    url: `/api/admin/users/${id}/notes?page=${page}`,
  });
  return data;
};

export const postNote = async (id, note) => {
  const { data } = await axiosInstance({
    method: "post",
    url: `/api/admin/users/${id}/notes`,
    data: { note_text: note },
  });
  return data;
};

export const deleteNote = async (id, noteId) => {
  const { data } = await axiosInstance({
    method: "delete",
    url: `/api/admin/users/${id}/notes/${noteId}`,
  });
  return data;
};

export const getTransactionsData = async (id, type) => {
  const { data } = await axiosInstance({
    method: "get",
    url: `/api/admin/users/${id}/${type}`,
  });
  return data;
};
