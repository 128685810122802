import React, { useEffect, useState } from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/bugStyle";
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Icons } from "helpers/icons";
import { configDate } from "helpers";

import { useQuery, useQueryClient } from "react-query";
import {
  getFeatureSingle,
  commentOnFeature,
  updateFeatureStatus
} from "services/endpoints/feature";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";

const FeaturePage = () => {
  const [newComment, setNewComment] = useState("");

  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const { data, error } = useQuery(
    ["getFeatureData", id],
    () => getFeatureSingle(id),
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (error) addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000 });

    // eslint-disable-next-line
  }, [error]);

  const postNewComment = async () => {
    if (newComment !== "") {
      const { error } = await commentOnFeature(id, { body: newComment });
      if (!error) {
        addToast("Comment added successfully", { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000 });
        queryClient.invalidateQueries("getBugData");
        setNewComment("");
      }
      if (error) {
        addToast("Something went wrong", { appearance: "error", autoDismissTimeout: 3000,autoDismiss: true});
      }
    }
  };
  
  const resolveFeature = async (stat) => {
    const { error } = await updateFeatureStatus(id, { status: stat });
    if (!error) {
      addToast("Status updated", { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000 });
      queryClient.invalidateQueries("getFeatureData");
    }
    if (error) {
      addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000 });
    }
  };
  
  const updateNewCommentText = (data) => setNewComment(data);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Button color="primary" onClick={() => history.goBack()}>
          <Icons.ArrowBack /> Return
        </Button>
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">Feature</CardHeader>
          <CardBody>
            <p>
              <b>Description:</b> {data?.body || ""}
            </p>
            <p>
              <b>Status:</b> {data?.status}
            </p>
            {data?.status === "in_progress" ?
                <Button
                    color="success"
                    style={{ float: "right" }}
                    onClick={() =>
                        resolveFeature(
                            data?.status === "in_progress" ? "resolved" : "in_progress"
                        )
                    }
                >
                  {data?.status === "in_progress"
                      ? "Mark as Resolved"
                      : "Mark as In progress"}
                </Button> : ""
            }
          </CardBody>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={12} md={12} >
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitlePrimary}>Comments</h4>
          </CardHeader>
          <CardBody>
            {data &&
              data.comments.map((e) => (
                <div
                  className={e.is_admin_response && classes.bugBubbleAdminWrap}
                  key={e.id}
                >
                  <div
                      className={
                        e.is_admin_response
                            ? classes.bugBubbleAdmin
                            : classes.bugBubbleGuest
                      }
                  >
                    <span>{e.is_admin_response ? "Fluffa" : e.user_profile.user.handle}</span>
                    <p>{e.body}</p>
                    <span>{configDate(e.created_at)}</span>
                  </div>
                </div>
              ))}

            <CustomInput
              labelText={`Write a response`}
              id="newcomment"
              formControlProps={{ fullWidth: true }}
              val={`${newComment}`}
              updateState={updateNewCommentText}
            />
            <Button color="success" onClick={postNewComment}>
              Submit
            </Button>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default FeaturePage;
