import React, { useEffect, useState } from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/dashboardStyle";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import { Icons } from "helpers/icons";
import { configDate } from "helpers";

import Icon from "@material-ui/core/Icon";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Error from "components/Error/Error";
import Tabs from "components/CustomTabs/CustomTabs.js";
import Button from "components/CustomButtons/Button";

import UsersGraph from "./UsersGraph";
import TopTotalBlock from "./TopTotalBlock";

import { getDashboardData, getRevenueData } from "services/endpoints/dashboard";

import { useQuery } from "react-query";

const Dash = () => {
  const [state, setState] = useState({
    last_5_users: [],
    new_users_graph_data: {},
    creators_count: 0,
    users_count: 0,
    posts_count: 0,
    online_count:0
  });
  const [revenue, setRevenue] = useState({
    daily: 0,
    weekly: 0,
    monthly: 0,
    all_time: 0,
  });
  const [error, setError] = useState(false);

  const history = useHistory();
  const classes = useStyles();
  const { addToast } = useToasts();

  const { data, isError } = useQuery("dashboard", getDashboardData, {
    refetchOnWindowFocus: false,
  });

  const { data: dataR, isError: isErrorR } = useQuery(
    "revenue",
    getRevenueData,
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isError || isErrorR) {
    setError(true);
    addToast("Something went wrong. Try reloading.", {
      appearance: "error",
      autoDismiss: true,
      autoDismissTimeout: 3000
    });
  }

  useEffect(() => {
    if (data) {
      setState({
        last_5_users: data.last_5_users,
        new_users_graph_data: data.new_users_graph_data,
        creators_count: data.creators_count,
        users_count: data.users_count,
        posts_count: data.posts_count,
        online_count: data.online_users
      });
    }
  }, [data]);

  useEffect(() => {
    if (dataR) {
      setRevenue(dataR);
    }
  }, [dataR]);

  const renderDashboard = () => {
    if (!error) {
      return (
        <>
          <GridItem xs={12} sm={6} md={3}>
            <TopTotalBlock
              link="creators"
              icon={<Icons.LinkedCamera />}
              color="rose"
              text="creators"
              stats={state.creators_count}
            />
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <TopTotalBlock
              link="all-users"
              icon={<Icons.Accessibility />}
              color="info"
              text="users"
              stats={state.users_count}
            />
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <TopTotalBlock
              link="#"
              icon={<Icons.Accessibility />}
              color="success"
              text="users online"
              stats={state.online_count}
            />
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <TopTotalBlock
              link="posts"
              icon={<Icon>content_copy</Icon>}
              color="warning"
              text="posts"
              stats={state.posts_count}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <Tabs
              title="Revenue:"
              headerColor="success"
              tabs={[
                {
                  tabName: "Daily",
                  tabIcon: Icons.ViewDay,
                  tabContent: <div>{revenue.daily.toFixed(2)}$</div>,
                },
                {
                  tabName: "Weekly",
                  tabIcon: Icons.ViewWeek,
                  tabContent: <div>{revenue.weekly.toFixed(2)}$</div>,
                },
                {
                  tabName: "Monthly",
                  tabIcon: Icons.CropSquare,
                  tabContent: <div>{revenue.monthly.toFixed(2)}$</div>,
                },
                {
                  tabName: "All time",
                  tabIcon: Icons.Timeline,
                  tabContent: <div>{revenue.all_time.toFixed(2)}$</div>,
                },
              ]}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={4}>
            <UsersGraph graphData={state.new_users_graph_data} />
          </GridItem>

          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>New users</h4>
              </CardHeader>
              <CardBody>
                <Table
                  profileLink={true}
                  tableHeaderColor="primary"
                  tableHead={["Id", "Username", "Email", "Created_at", ""]}
                  tableData={state.last_5_users.map((user) => [
                    `${user.id}`,
                    `${user.handle}`,
                    `${user.email}`,
                    `${user.created_at && configDate(user.created_at)}`,
                    <div style={{ float: "right" }}>
                      <Button
                        color="info"
                        onClick={() =>
                          history.push(`/admin/profile/${user.id}`)
                        }
                      >
                        <Icons.RemoveRedEyeIcon />
                      </Button>
                    </div>,
                  ])}
                />
              </CardBody>
            </Card>
          </GridItem>
        </>
      );
    } else {
      return (
        <GridItem xs={12} sm={12} md={12}>
          <Error />
        </GridItem>
      );
    }
  };

  return <GridContainer>{renderDashboard()}</GridContainer>;
};

export default Dash;
