import React from "react";
import Tabs from "../../components/CustomTabs/CustomTabs.js";
import PostsTable from "./PostsTable";

import { Icons } from "helpers/icons";

export const AllPosts = () => {
  return (
    <Tabs
      title="Posts:"
      headerColor="rose"
      tabs={[
        {
          tabName: "Posts",
          tabIcon: Icons.PermMedia,
          tabContent: (
            <PostsTable api="admin/posts" header="All posts" type="posts" />
          ),
        },
      ]}
    />
  );
};

export const ReportedPosts = () => {
  return (
    <Tabs
      title="Posts:"
      headerColor="rose"
      tabs={[
        {
          tabName: "Reports",
          tabIcon: Icons.Warning,
          tabContent: (
            <PostsTable
              api="admin/reports/posts"
              header="Reported posts"
              type="reports"
            />
          ),
        },
      ]}
    />
  );
};

export const BlockedPosts = () => {
  return (
    <Tabs
      title="Posts:"
      headerColor="rose"
      tabs={[
        {
          tabName: "Blocked",
          tabIcon: Icons.Block,
          tabContent: (
            <PostsTable
              api="admin/blocked/posts"
              header="Blocked posts"
              type="reports"
            />
          ),
        },
      ]}
    />
  );
};

export const UnapprovedPosts = () => {
  return (
    <Tabs
      title='Posts:'
      headerColor='rose'
      tabs={[
        {
          tabName: 'Unapproved',
          tabIcon: Icons.RemoveCircleOutlineIcon,
          tabContent: (
            <PostsTable
              api='admin/pending/posts'
              header='Unapproved posts'
              type='posts'
            />
          ),
        },
      ]}
    />
  );
};


export const DeletedPosts = () => {
  return (
    <Tabs
      title='Posts:'
      headerColor='rose'
      tabs={[
        {
          tabName: 'Deleted',
          tabIcon: Icons.RemoveCircleOutlineIcon,
          tabContent: (
            <PostsTable
              api='admin/posts/get/deleted-reports'
              header='Deleted posts'
              type='deleted'
            />
          ),
        },
      ]}
    />
  );
};