import React, { useState, useEffect, useRef } from "react";
import { useToasts } from "react-toast-notifications";
import { configDate } from "helpers";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import Pagination from "components/Pagination/Pagination";

import { useQuery } from "react-query";
import { getTransactionsData } from "services/endpoints/users";

const Transactions = ({ id, type }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const scrollTarget = useRef(null);

  const { addToast } = useToasts();

  const configType = (e) => e.slice(e.lastIndexOf("\\") + 1, e.length);

  const { data, error } = useQuery(
    ["gettransactions", id, type],
    () => getTransactionsData(id, type),
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (data) {
      setCurrentPage(data.current_page);
      setTotalPages(data.last_page);
      setLoading(false);
    } else {
      setLoading(true);
    }
    if (error) {
      addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000});
      setLoading(false);
    }
  }, [data, error, addToast]);

  const configTable = () => {
    if (type === "earnings") {
      const head = [
        "ID",
        "Creator earnings",
        "Created on",
        "Product Type",
        "Transaction",
        "Credit Card",
        "Payment Gateway",
        "User's ID",
        "User's username",
        "Total amount",
        "Net Profit",
      ];
      const body = data?.data.map((item) => [
        `${item.id}`,
        `${item.creator_earnings?.toFixed(2) || ""} ${
          (item.creator_earnings && item.currency) || ""
        }`,
        `${configDate(item.created_at) || ""}`,
        `${configType(item.product_type)}`,
        `${item.transaction_type.split("_").join(" ")}`,
        `${item.last4 || ""}`,
        `${configType(item.transactionable_type)}`,
        `${item.user_profile?.id}`,
        `${item.user_profile?.user?.handle || ""}`,
        `${item.amount} ${item.currency}`,
        `${(item.amount - item.creator_earnings).toFixed(2)} ${item.currency}`,
      ]);

      return (
        <Table
          transactionLink={true}
          tableHeaderColor="primary"
          tableHead={head}
          tableData={body}
        />
      );
    } else if (type === "spending") {
      const head = [
        "ID",
        "Creator earnings",
        "Created on",
        "Product Type",
        "Transaction",
        "Credit Card",
        "Payment Gateway",
        "Creator ID",
        "Creator",
        "Total amount",
        "Net Profit",
      ];
      const body = data?.data.map((item) => [
        `${item.id}`,
        `${item.creator_earnings?.toFixed(2) || ""} ${
          (item.creator_earnings && item.currency) || ""
        }`,
        `${configDate(item.created_at) || ""}`,
        `${configType(item.product_type)}`,
        `${item.transaction_type.split("_").join(" ")}`,
        `${item.last4 || ""}`,
        `${configType(item.transactionable_type)}`,
        `${item.creator_profile?.id || ""}`,
        `${item.creator_profile?.user?.handle || ""}`,
        `${item.amount} ${item.currency}`,
        `${(item.amount - item.creator_earnings).toFixed(2)} ${item.currency}`,
      ]);

      return (
        <Table
          transactionLink={true}
          tableHeaderColor="primary"
          tableHead={head}
          tableData={body}
        />
      );
    }
  };

  return (
    <Card>
      <CardHeader color="primary">
        <span ref={scrollTarget}>
          {type.charAt(0).toUpperCase() + type.slice(1)}
        </span>
      </CardHeader>
      <CardBody>
        {data?.data.length > 0
          ? configTable()
          : "No transactions have been made"}
      </CardBody>
      {data?.data.length > 0 && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          loading={loading}
          scrollTarget={scrollTarget}
        />
      )}
    </Card>
  );
};

export default Transactions;
