import React, { useState, useEffect, useRef } from "react";
import { useToasts } from "react-toast-notifications";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Pagination from "components/Pagination/Pagination";

import { configDate } from "helpers";

import { getNotesData, postNote, deleteNote } from "services/endpoints/users";
import { useQuery, useQueryClient } from "react-query";

const Notes = ({ id }) => {
  const [allNotes, setAllNotes] = useState([]);
  const [newNote, setNewNote] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const scrollTarget = useRef(null);

  const { addToast } = useToasts();
  const queryClient = useQueryClient();

  const updateNewNoteText = (data) => setNewNote(data);

  const { data, error } = useQuery(
    ["getNotes", id, currentPage],
    () => getNotesData(id, currentPage),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data) {
      setAllNotes(data.data);
      setCurrentPage(data.current_page);
      setTotalPages(data.last_page);
      setLoading(false);
    } else {
      setLoading(true);
    }
    if (error) {
      addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000});
      setLoading(false);
    }
    //eslint-disable-next-line
  }, [data, error]);

  const postNewNote = async () => {
    if (newNote !== "") {
      const { error } = await postNote(id, newNote);
      if (!error) {
        queryClient.invalidateQueries("getNotes");
        setNewNote("");
        addToast("Note ADDED", { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000});
      } else {
        addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000});
      }
    }
  };

  const removeNote = async (noteId) => {
    const { error } = await deleteNote(id, noteId);
    if (!error) {
      addToast("Note REMOVED", { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000});
      queryClient.invalidateQueries("getNotes");
    } else {
      addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000});
    }
  };

  const configTable = () => {
    const head = ["ID", "Note", "Created on", ""];
    const body = allNotes.map((note) => [
      `${note.id}`,
      `${note.note_text}`,
      `${configDate(note.created_at)}`,
      <div style={{ float: "right" }}>
        <Button
          color="primary"
          onClick={() =>
            window.confirm("Delete the item?") && removeNote(note.id)
          }
        >
          X
        </Button>
      </div>,
    ]);

    return (
      <Table tableHeaderColor="primary" tableHead={head} tableData={body} />
    );
  };

  return (
    <Card>
      <CardHeader color="primary">
        <span ref={scrollTarget}>Notes</span>
      </CardHeader>
      <CardBody>
        <CustomInput
          labelText={`Add new note`}
          id="newnote"
          formControlProps={{ fullWidth: true }}
          val={`${newNote}`}
          updateState={updateNewNoteText}
        />
        <Button color="success" onClick={postNewNote}>
          Submit
        </Button>
      </CardBody>
      <CardBody>
        {allNotes.length > 0 ? configTable() : "No notes yet"}
      </CardBody>
      {allNotes.length > 0 && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          loading={loading}
          scrollTarget={scrollTarget.current}
        />
      )}
    </Card>
  );
};

export default Notes;
