import React, { useState, useEffect, useRef } from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/usersStyle";
import { useToasts } from "react-toast-notifications";
import { Icons } from "helpers/icons";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button";
import Pagination from "components/Pagination/Pagination";
import { Loader } from "components/Loader/Loader";
import CustomInput from "../../components/CustomInput/CustomInput";

import { getRestrictedWords,postWord,deleteWord } from "services/endpoints/badWords";
import { useQuery,useQueryClient } from "react-query";

import RestrictedWordsPopup from "./RestrictedWordsPopup"

const RestrictedWords = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [loading, setLoading] = useState(false);

    const scrollTarget = useRef(null);
    const classes = useStyles();
    const { addToast } = useToasts();
    const queryClient = useQueryClient();

    const { data, error } = useQuery(
        ["getAllWords", currentPage],
        () => getRestrictedWords(currentPage),
        { refetchOnWindowFocus: false }
    );

    const [restrictedWordsPopup, setRestrictedWordsPopup] = useState({
        active: null,
    });

    const renderWordsTable = (arr) => {
        const wordData = arr.map((word,i) => [
            `${word.id}`,
            `${word.word}`,
            <div style={{ float: "right" }}>
                {restrictedWordsPopup.active === word.id && (
                    <div     className={`${classes.verificationPopup} ${
                        i < arr.length / 2
                            ? classes.verificationPopupTop
                            : classes.verificationPopupBot
                    } ${
                          classes.verificationPopupVerified
                    } restrictedPopupResponsive`}
                    >
                        <RestrictedWordsPopup
                            setpopup={setRestrictedWordsPopup}
                            word={word.word}
                            wordId={word.id}
                        />
                    </div>
                )}
                <Button
                    color="primary"
                    onClick={() =>
                        window.confirm("Delete the item?") && removeWord(word.id)
                    }
                >
                    <Icons.Clear />
                </Button>
                <Button
                    color="info"
                    title="Visit profile"
                    onClick={() => {
                        if (
                            restrictedWordsPopup.active &&
                            restrictedWordsPopup.active === word.id
                        ) {
                            setRestrictedWordsPopup({
                                active: null,
                            });
                        } else {
                            setRestrictedWordsPopup({
                                active: word.id,
                            });
                        }
                    }}
                >
                    <Icons.RemoveRedEyeIcon />
                </Button>
            </div>,
        ]);

        return (
            <Table
                tableHeaderColor="primary"
                tableHead={["ID", "Word",""]}
                tableData={[...wordData]}
            />
        );
    };

    const [newWord, setNewWord] = useState("");
    const updateNewWordText = (data) => setNewWord(data);

    const postNewWord = async () => {
        if (newWord !== "") {
            const {error} = await postWord(newWord);
            if (!error) {
                setNewWord("");
                addToast("Restricted word ADDED", {appearance: "success"});
                queryClient.invalidateQueries("getAllWords");
            } else {
                addToast("Something went wrong", {appearance: "error"});
            }
        }
    }

    const removeWord = async (wordId) => {
        const { error } = await deleteWord(wordId);
        if (!error) {
            addToast("Word REMOVED", { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000});
            queryClient.invalidateQueries("getAllWords");
        } else {
            addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000});
        }
    };

    useEffect(() => {
        if (data) {
            setTotalPages(data.meta.last_page);
            setLoading(false);
        } else {
            setLoading(true);
        }
        if (error) {
            setLoading(false);
            addToast("Something went wrong. Try reloading.", {
                appearance: "error",
            });
        }
    }, [data, error,addToast]);

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitlePrimary} ref={scrollTarget}>
                            Restricted Words
                        </h4>
                        <p className={classes.cardCategoryPrimary}>
                            List of all restricted words
                        </p>
                    </CardHeader>
                    <CardBody>
                        <CustomInput
                            labelText={`Add new restricted word`}
                            id="newWord"
                            formControlProps={{ fullWidth: true }}
                            val={`${newWord}`}
                            updateState={updateNewWordText}
                        />
                        <Button color="success" onClick={postNewWord}>
                            Submit
                        </Button>
                        {data && renderWordsTable(data.data)}
                        {loading && <Loader />}
                    </CardBody>
                    {data && data.data.length > 0 && (
                        <Pagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPages={totalPages}
                            loading={loading}
                            scrollTarget={scrollTarget.current}
                        />
                    )}
                </Card>
            </GridItem>
        </GridContainer>
    );
};

export default RestrictedWords;
