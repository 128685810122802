import React from "react";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import { useStyles } from "assets/jss/material-dashboard-react/views/usersStyle";

const AutoApprove = () => {

    const classes = useStyles();

    return (
        <Card>
            <CardHeader color="primary">
                <h4 className={classes.cardTitlePrimary}>Automatically approve posts</h4>
            </CardHeader>
            <CardBody>
                <Button color="success">
                    On
                </Button>
                <Button
                    color="primary"
                >
                    Off
                </Button>
            </CardBody>
        </Card>
    );
};

export default AutoApprove;
