import React, {useState } from "react";
import { useToasts } from "react-toast-notifications";
import CustomInput from "../../components/CustomInput/CustomInput";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button";
import { postUser } from "services/endpoints/register";
import "./register.css";

const Register = ({ api }) => {

    const {addToast} = useToasts();

    const [user, setUser] = useState({
        handle: "",
        name: "",
        email: "",
        password: ""
    });

    const updateName = (data) => setUser({...user, handle: data});
    const updateUsername = (data) => setUser({...user, name: data});
    const updateEmail = (data) => setUser({...user, email: data});
    const updatePassword = (data) => setUser({...user, password: data});

    const createUser = async () => {
        try {
            await postUser(user);
            setUser({name: "", handle: "", password: "", email: ""})
            addToast("New user CREATED", {appearance: "success"});
        }catch (e) {
            addToast("Please,check credentials", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000});
        }
    }

    return (
        <GridContainer>

            <GridItem xs={12} sm={12} md={12} >
                <Card>
                    <CardHeader color="primary">Create a new user</CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={5}>
                                <div className="input-search-user">
                                    <CustomInput
                                        labelText="Name"
                                        id="name-user"
                                        formControlProps={{ fullWidth: true }}
                                        val={`${user.handle}`}
                                        updateState={updateName}
                                    />
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={5}>
                                <div className="input-search-user">
                                    <CustomInput
                                        labelText="Username"
                                        id="username-user"
                                        formControlProps={{ fullWidth: true }}
                                        val={`${user.name}`}
                                        updateState={updateUsername}
                                    />
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={5}>
                                <div className="input-search-user">
                                    <CustomInput
                                        labelText="Email"
                                        id="email-user"
                                        formControlProps={{ fullWidth: true }}
                                        val={`${user.email}`}
                                        updateState={updateEmail}
                                    />
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={5}>
                                <div className="input-search-user">
                                    <CustomInput
                                        labelText="Password"
                                        id="password-user"
                                        formControlProps={{ fullWidth: true }}
                                        val={`${user.password}`}
                                        updateState={updatePassword}
                                        type={"password"}
                                    />
                                </div>
                            </GridItem>
                            <div className={"create-user-button"}>
                                <Button color="success" onClick={() => createUser()}>
                                    Create
                                </Button>
                            </div>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}
export default Register;
