import axiosInstance from "api/axiosInstance";

export const getPromotionsData = async (page = 1, api) => {
    const { data } = await axiosInstance({
        method: "get",
        url: `/api/${api}?page=${page}`,
    });

    return data;
};

export const postPromotion = async (user, days) => {
    const { data } = await axiosInstance({
        method: "post",
        url: `/api/promotions`,
        data: { user_profile_id: Number(user), days_count:Number(days) },
    });
    return data;
};

export const deletePromotion = async (id) => {
    const { data } = await axiosInstance({
        method: "delete",
        url: `/api/promotions/${id}`,
    });
    return data;
};