import React from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/postsStyle";
import { Link } from "react-router-dom";
import { configDate } from "helpers";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

const User = ({sender, reportee, time, reported_reason}) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitlePrimary}>Info</h4>
      </CardHeader>
      <CardBody>
        <p>
          Reported account:{" "}
          <Link className={classes.userProfileLink} to={`/admin/profile/${sender.id}`}>
            {sender.name} - @{sender.handle}
          </Link>
        </p>
        <p>
          Report submitted by:{" "}
          <Link className={classes.userProfileLink} to={`/admin/profile/${reportee.id}`}>
            {reportee.name} - @{reportee.handle}
          </Link>
        </p>
        <p>Reported for: {reported_reason}</p>
        <p>Reported on: {configDate(time)}</p>
      </CardBody>
    </Card>
  );
};

export default User;
