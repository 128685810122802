import React from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/postsStyle";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

const Videos = ({ videos }) => {
    const classes = useStyles();

    return (
        <Card>
            <CardHeader color="primary">
                <h4 className={classes.cardTitlePrimary}>Videos</h4>
            </CardHeader>
            <CardBody>
                {videos.map((e) => (
                    <video
                        className={classes.postImage}
                        src={e}
                        controls
                    ></video>
                ))}
            </CardBody>
        </Card>
    );
};

export default Videos;
