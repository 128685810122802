import axiosInstance from "api/axiosInstance";

export const postUser = async (user) => {
    const { data } = await axiosInstance({
        method: "post",
        url: `/api/auth/register`,
        data: {name: user.name, handle: user.handle, email: user.email, password: user.password},
    });
    return data;
};
