// TOKEN
export const setAccessToken = token => localStorage.setItem('access_token', JSON.stringify(token))
export const removeAccessToken = () => localStorage.clear()
export const getAccessToken = () => {
    const token = localStorage.getItem('access_token')
    return JSON.parse(token)
}

// EXPIRATION
export const setExpirationDate = date => localStorage.setItem('expiration_date', date)
export const getExpirationDate = () => localStorage.getItem('expiration_date')

// REFRESH
export const setRefreshTime = date => localStorage.setItem('refresh_date', date)
export const getRefreshTime = date => localStorage.getItem('refresh_date')