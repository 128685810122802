import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useToasts } from "react-toast-notifications";
import { getSingleMessageData } from "services/endpoints/messages";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { useHistory, useParams } from "react-router-dom";
import Button from "components/CustomButtons/Button";
import { Icons } from "helpers/icons";
import Text from "./components/Text";
import User from "./components/User";
import Delete from "./components/Delete";
import Approve from "./components/Approve";

export const MessagesView = (props) => {
  const [message, setMessage] = useState();
  const { addToast } = useToasts();
  const { id } = useParams();
  const history = useHistory();

  const {data, error} = useQuery(
    ["singleMessageData", id],
    () => getSingleMessageData(id),
    { refetchOnWindowFocus: false }
  );

  const [deleteInput, setDeleteInput] = useState(false);
  const [approveInput, setApproveInput] = useState(false);
  const [reason, setReason] = useState('');
  
  useEffect(() => {
    if (data) {
      setMessage(data.data[0]);
    }
    if (error) {
      addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000});
    }
    // eslint-disable-next-line
  }, [data, error]);

  const deleteMessage = async (reason) => {
    try{
      history.push("/admin/reported-messages");
      addToast("Message deleted successfully", { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000});
    } catch (e) {
      addToast("This message was already deleted!", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000});
    }
  }

  const approveMessage = async () => {
    try{
      history.push("/admin/reported-messages");
      addToast("Message dismissed", { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000});
    } catch (e) {
      addToast("This message is not reported!", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000});
    }
  }

  const renderMessage = () => {
    if(message) {
      return (
        <>
          <GridContainer>
            {/* RETURN */}
            <GridItem xs={12} sm={12} md={12}>
              <Button color="primary" onClick={() => history.goBack()}>
                <Icons.ArrowBack /> Return
              </Button>
            </GridItem>

            {message.user_profile?.user && (
              <GridItem xs={12} sm={12} md={12}>
                <User
                  reportee={{
                    id: message.user_profile.user.id,
                    name: message.user_profile.user.name,
                    handle: message.user_profile.user.handle,
                  }}
                  sender={{
                    id: message.reportable.sender.user.id,
                    name: message.reportable.sender.user.name,
                    handle: message.reportable.sender.user.handle,
                  }}
                  reported_reason={message.reason}
                  time={message.created_at}
                />
              </GridItem>
            )}

            {message.reportable?.body && (
              <GridItem xs={12} sm={12} md={12}>
                <Text text={message.reportable.body} />
              </GridItem>
            )}
            
            <GridItem xs={12} sm={12} md={12}>
              <Approve
                  approveMessage={approveMessage }
                  approveInput={approveInput}
                  setApproveInput={setApproveInput}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Delete
                deleteMessage={deleteMessage}
                deleteInput={deleteInput}
                setDeleteInput={setDeleteInput}
                deleteReason = {reason}
                setDeleteReason = {setReason}
              />
            </GridItem>
          </GridContainer>
        </>
      );
    }
  }
    return (
        <div>{renderMessage()}</div>
      );
}