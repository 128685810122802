import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
// import { useStyles } from 'assets/jss/material-dashboard-react/views/postsStyle'
import { useToasts } from "react-toast-notifications";
import { Icons } from "helpers/icons";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button";

import Text from "./components/Text";
import Images from "./components/Images";
import Videos from "./components/Videos";
import User from "./components/User";
import Delete from "./components/Delete";

import { permanentlyDeleteSinglePost } from "services/endpoints/posts";
import { useQuery } from "react-query";
import { getSingleDeletedPostData } from "services/endpoints/posts";
import Restore from "./components/Restore";

const DeletedPost = () => {
  const history = useHistory();
  const { id } = useParams();
  const [post, setPost] = useState();
  const [deleteInput, setDeleteInput] = useState(false);
  const [restoreInput, setRestoreInput] = useState(false);

  // const classes = useStyles();
  const { addToast } = useToasts();

  const { data, error } = useQuery(
    ["singlePostData", id],
    () => getSingleDeletedPostData(id),
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (data) {
      setPost(data);
    }
    if (error) {
      addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000});
    }
    // eslint-disable-next-line
  }, [data, error]);

  const deletePost = async () => {
    const { error } = await permanentlyDeleteSinglePost(id);
    if (!error) {
      history.push("/admin/deleted-posts");
      addToast("Post permanently removed successfully", { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000});
    }
    if (error) addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000});
  };

  const restorePost = async () => {
    try{
      history.push("/admin/unapproved-posts");
      addToast("Post restored successfully", { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000});
    }catch (e) {
      addToast("The post already visible!", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000});
    }
  };

  const renderPost = () => {
    if (post) {
      return (
        <>
          <GridContainer>
            {/* RETURN */}
            <GridItem xs={12} sm={12} md={12}>
              <Button color="primary" onClick={() => history.goBack()}>
                <Icons.ArrowBack /> Return
              </Button>
            </GridItem>

            {post.user_profile?.user && (
              <GridItem xs={12} sm={12} md={12}>
                <User
                  id={post.user_profile.user.id}
                  name={post.user_profile.user.name}
                  handle={post.user_profile.user.handle}
                  time={post.created_at}
                />
              </GridItem>
            )}


            {post.text && (
              <GridItem xs={12} sm={12} md={12}>
                <Text text={post.text} />
              </GridItem>
            )}

            {post.post_images.length > 0 && (
              <GridItem xs={12} sm={12} md={12}>
                <Images images={post.post_images} />
              </GridItem>
            )}

            {post.post_videos.length > 0 && (
              <GridItem xs={12} sm={12} md={12}>
                <Videos videos={post.post_videos} />
              </GridItem>
            )}

            <GridItem xs={12} sm={12} md={12}>
              <Restore
                  approvePost={restorePost}
                  approveInput={restoreInput}
                  setApproveInput={setRestoreInput}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Delete
                deletePost={deletePost}
                deleteInput={deleteInput}
                setDeleteInput={setDeleteInput}
              />
            </GridItem>
          </GridContainer>
        </>
      );
    }
  };

  return <div>{renderPost()}</div>;
};

export default DeletedPost;
