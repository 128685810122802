import React, { useEffect, useState, useRef } from "react";

import { useStyles } from "assets/jss/material-dashboard-react/views/usersStyle";
import "./usersTable.css";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import { Icons } from "helpers/icons";
import { configDate } from "helpers";
// import TableSortLabel from '@material-ui/core/TableSortLabel';
import axiosInstance from "api/axiosInstance";
import CustomInput from "components/CustomInput/CustomInput";
import VerificationPopup from "./VerificationPopup";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button";
import Pagination from "components/Pagination/Pagination";
import { Loader } from "components/Loader/Loader";

import { getUsersData } from "services/endpoints/users";
import {
  postApproveProfile,
  postRejectProfile,
} from "services/endpoints/users";
import { useQuery, useQueryClient } from "react-query";

const UsersTable = ({ api, search, header, tabletext }) => {
  const [users, setUsers] = useState([]);
  // eslint-disable-next-line
  const [sortByDate, setSortByDate] = useState('desc');
  // eslint-disable-next-line
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [loading, setLoading] = useState(false);

  const [profileVerificationPopup, setProfileVerificationPopup] = useState({
    active: null,
    profile: {},
    name: "",
  });

  const scrollTarget = useRef(null);

  const classes = useStyles();
  const history = useHistory();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();

  const { data, refetch, error } = useQuery(
    [`${api}`, currentPage, api, sortByDate],
    () => getUsersData(currentPage, api, searchTerm, sortByDate),
    { refetchOnWindowFocus: false }
  );
  useEffect(() => {
    refetch();
  }, [searchTerm,refetch]);

  useEffect(() => {
    if (data) {
      setUsers(data.data);
      setTotalPages(data.last_page);
      setLoading(false);
    } else {
      setLoading(true);
    }

    if (error) {
      setLoading(false);
      addToast("Something went wrong. Try reloading.", {
        appearance: "error",
      });
    }
    // eslint-disable-next-line
  }, [data, error]);

  const searchForUser = (data) => {
    setSearchTerm(data);
  };

  const approveProfile = async (id) => {
    const { data, error } = await postApproveProfile(id);
    if (data) {
      setProfileVerificationPopup({
        active: null,
        name: "",
        profile: {},
      });
      addToast("User VERIFIED successfully", { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000});
      queryClient.invalidateQueries(`${api}`);
    }
    if (error) {
      addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000});
    }
  };

  const rejectProfile = async (msg) => {
    const { data, error } = await postRejectProfile(
      profileVerificationPopup.active,
      msg
    );
    if (data) {
      setProfileVerificationPopup({
        active: null,
        name: "",
        profile: {},
      });
      addToast("User REJECTED successfully", { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000});
      queryClient.invalidateQueries(`${api}`);
    }
    if (error) {
      addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000});
    }
  };
  
  const banProfile = (action,id) => {
    axiosInstance({
      method: "post",
      url: `/api/admin/users/${id}/ban?action=0`,
    })
        .then((resp) =>
                refetch(),
                addToast(`User unbanned successfully`, { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000}),
        )
        .catch((err) =>
            addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000})
        );
  };

  const renderUsersTable = (data) => {
    const usersData = data.map((user, i) => [
      `${user.id}`,
      `${user.handle}`,
      `${user.name}`,
      `${user.email}`,
      `${user.created_at && configDate(user.created_at)}`,
      api === "users/deactivated" ? user.deleted_at && configDate(user.deleted_at) : api === "users/banned" ? user.updated_at && configDate(user.updated_at) : "",
      `${user.email_verified_at ? "✓" : "✗"}`,
      `${user?.profile?.face_verified_at ? "✓" : "✗"}`,
      <div style={{ textAlign: "right" }}>
        {profileVerificationPopup.active === user.id && (
          <div
            className={`${classes.verificationPopup} ${
              i < data.length / 2
                ? classes.verificationPopupTop
                : classes.verificationPopupBot
            } ${
              user?.profile?.face_verified_at !== null
                ? classes.verificationPopupVerified
                : classes.verificationPopupNotVerified
            } verificationPopupResponsive`}
          >
            <VerificationPopup
              id={user.id}
              banned={user.banned}
              profile={profileVerificationPopup.profile}
              name={profileVerificationPopup.name || ""}
              setpopup={setProfileVerificationPopup}
              approveProfile={() =>
                approveProfile(profileVerificationPopup.active)
              }
              rejectProfile={rejectProfile}
              email={user.email}
              emailVerified={user.email_verified_at}
              handle={user.handle}
              refetchUsers={refetch}
            />
          </div>
        )}
        {api !== "users/banned" ?
            <Button
                className="user-verify-button"
                color={user?.profile?.face_verified_at ? "success" : "primary"}
                title="Verify profile"
                onClick={() => {
                  if (
                      profileVerificationPopup.active &&
                      profileVerificationPopup.active === user.id
                  ) {
                    setProfileVerificationPopup({
                      active: null,
                      profile: {},
                      name: "",
                    });
                  } else {
                    setProfileVerificationPopup({
                      active: user.id,
                      profile: user.profile,
                      name: user.name,
                    });
                  }
                }}
            >
              {user?.profile?.face_verified_at ? <Icons.Check /> : <Icons.Clear />}
            </Button> :
            <Button color="warning" onClick={() => banProfile(false,user.id)}>
              Unban Profile
            </Button>
        }
        <Button
          color="info"
          title="Visit profile"
          onClick={() => history.push(`profile/${user.id}`)}
        >
          <Icons.RemoveRedEyeIcon />
        </Button>
      </div>,
    ]);

    const cells = [
      "ID",
      "Username",
      "Name",
      "Email",
      "Join Date",
      api === "users/deactivated" ? "Deactivated Date" : api === "users/banned" ? "Banned Date" : "",
      "Email verified",
      "Face verified",
      <div className="sort" onClick={()=> {sortByDate === 'desc' ? setSortByDate('asc') : setSortByDate('desc')}}>
        Sort by {sortByDate === "desc" ? <>newest <Icons.ArrowUpward /></>  : <>oldest <Icons.ArrowDownward /></>}
      </div>
      // <TableSortLabel active={true} direction={"desc"}>Sort by Date</TableSortLabel>
    ]

    return (
      <Table
        profileLink={true}
        tableHeaderColor="primary"
        tableHead={cells}
        tableData={[...usersData]}
      />
    );
  };

  return (
    <GridContainer>
       <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">Search users</CardHeader>
          <CardBody>
            <CustomInput
              labelText="Enter username, name or email"
              id="search"
              formControlProps={{ fullWidth: true }}
              val={`${searchTerm}`}
              updateState={searchForUser}
            />
          </CardBody>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitlePrimary} ref={scrollTarget}>
              {header}
            </h4>
            <p className={classes.cardCategoryPrimary}>{tabletext}</p>
          </CardHeader>
          <CardBody>
            {users && users.length > 0
              ? renderUsersTable(users)
              : !loading && <p>No users</p>}
            {loading && <Loader />}
          </CardBody>
          {users && users.length > 0 && (
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
              loading={loading}
              scrollTarget={scrollTarget.current}
            />
          )}
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default UsersTable;
