import { makeStyles } from "@material-ui/core/styles";

const styles = {
  paginationButtonsContainer: {
    display: "flex",
    overflowX: "auto",
  },
  paginationButton: {
    background: "#ff4443",
    color: "#FFF",
  },
};

export const useStyles = makeStyles(styles);
