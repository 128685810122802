import React, { useEffect } from "react";
import {
  getAccessToken,
  removeAccessToken,
  getExpirationDate,
} from "../../api/storage";
import { useHistory } from "react-router-dom";
import Login from "../Login/Login";
import Admin from "../../layouts/Admin";

const Home = () => {
  const history = useHistory();
  useEffect(() => {
    let now = new Date();
    let then = new Date(getExpirationDate());
    if (now.getTime() > then.getTime()) removeAccessToken();
    if(!getAccessToken()) {
      history.push("/")
    }
  }, [history]);

  return !getAccessToken() ? <Login /> : <Admin />;
};

export default Home;
