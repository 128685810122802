import React from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/dashboardStyle";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";

import ChartistGraph from "react-chartist";
import { dailyUsersChart } from "variables/charts.js";

import { Icons } from "helpers/icons";

const UsersGraph = (props) => {
  const classes = useStyles();
  const { graphData } = props;

  const now = new Date();

  const configGraphData = (data) => {
    const graphData = {};
    if (data) {
      graphData.labels = Object.keys(data).map((e) => {
        let date = e.split("-");
        return `${date[1]}. ${date[2]}.`;
      });
      graphData.series = [Object.values(data)];
    }
    return graphData;
  };

  const configGraphOptions = (data) => {
    if (data) {
      const highVal = Math.max(...Object.values(data));
      const options = {
        low: 0,
        high: highVal + 5,
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      };
      return options;
    }
  };

  const configGraphStats = (data) => {
    if (data) {
      const vals = Object.values(data);
      let change;
      if (vals[vals.length - 1] * 100 !== 0 && vals[vals.length - 2] !== 0) {
        change =
          100 -
          Math.floor((vals[vals.length - 1] * 100) / vals[vals.length - 2]);
      } else if (
        vals[vals.length - 1] * 100 === 0 &&
        vals[vals.length - 2] !== 0
      ) {
        change = 100;
      } else if (
        vals[vals.length - 1] * 100 !== 0 &&
        vals[vals.length - 2] === 0
      ) {
        change = -100;
      }

      // console.log(vals[vals.length - 1] * 100, vals[vals.length - 2] * 100);
      return (
        <p className={classes.cardCategory}>
          {change > 0 ? (
            <>
              <span className={classes.successText}>
                <Icons.ArrowDownward className={classes.upArrowCategory} />
              </span>{" "}
              {change}% decrease.
            </>
          ) : (
            <>
              {isNaN(change) ? null : (
                <span className={classes.successText}>
                  <Icons.ArrowUpward className={classes.upArrowCategory} />
                </span>
              )}{" "}
              {isNaN(change) ? "No change" : `${Math.abs(change)}% increase.`}
            </>
          )}
        </p>
      );
    }
  };

  return (
    <Card chart>
      <CardHeader color="info">
        <ChartistGraph
          className="ct-chart"
          data={configGraphData(graphData)}
          type="Line"
          options={configGraphOptions(graphData)}
          listener={dailyUsersChart.animation}
        />
      </CardHeader>
      <CardBody>
        <h4 className={classes.cardTitle}>Daily new users</h4>
        {configGraphStats(graphData)}
      </CardBody>
      <CardFooter chart>
        <div className={classes.stats}>
          <Icons.AccessTime /> updated at {now.toLocaleTimeString()}.
        </div>
      </CardFooter>
    </Card>
  );
};

export default UsersGraph;
