import React, { useState } from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/usersStyle";
import { useToasts } from "react-toast-notifications";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import { updateWord } from "services/endpoints/badWords";
import { useQueryClient } from "react-query";
import { Icons } from "helpers/icons";

const RestrictedWordsPopup =
    ({
       setpopup,
       word,
       wordId
     }) => {

    const classes = useStyles();
    const [newWord, setNewWord] = useState(word);
    const updateNewWordText = (data) => setNewWord(data);
    const { addToast } = useToasts();
    const queryClient = useQueryClient();

    const updateRestrictedWord = async () => {
        if (newWord !== "") {
            const {error} = await updateWord(newWord,wordId);
            if (!error) {
                setNewWord(newWord);
                addToast("Restricted word UPDATED", {appearance: "success"});
                queryClient.invalidateQueries("getAllWords");
                setpopup({
                    active: null
                });
            } else {
                addToast("Something went wrong", {appearance: "error"});
            }
        }
    }

    return (
        <>
            <div className={classes.verificationPopupContainer}>
                <CustomInput
                    labelText={`Edit restricted word`}
                    id="newWord"
                    formControlProps={{ fullWidth: true }}
                    val={`${newWord}`}
                    updateState={updateNewWordText}
                />
                <Button
                    color="success"
                    onClick={updateRestrictedWord}
                >
                    Edit
                </Button>
                <Icons.HighlightOff
                    className={classes.verificationPopupClose}
                    onClick={() => {
                        setpopup({
                            active: null
                        });
                    }}
                />
            </div>
        </>
    );
};

export default RestrictedWordsPopup;
