import React from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/postsStyle";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

const PermDelete = ({ deleteMessage, deleteInput, setDeleteInput }) => {
  const classes = useStyles();


  return (
    <Card>
      <CardHeader color="warning">
        <h4 className={classes.cardTitleWhite}>Permanently delete media</h4>
      </CardHeader>
      <CardBody>
        {deleteInput ? (
          <div>
            <div>
              <Button color="primary" onClick={() => deleteMessage()}>
                Confirm
              </Button>
              <Button color="warning" onClick={() => setDeleteInput(false)}>
                X
              </Button>
            </div>
          </div>
        ) : (
          <Button color="warning" onClick={() => setDeleteInput(true)}>
            Delete
          </Button>
        )}
      </CardBody>
    </Card>
  );
};

export default PermDelete;
