import axiosInstance from "api/axiosInstance";

export const getDashboardData = async () => {
  const { data } = await axiosInstance({
    method: "get",
    url: `/api/admin`,
  });
  return data;
};

export const getRevenueData = async () => {
  const { data } = await axiosInstance({
    method: "get",
    url: "/api/admin/revenue/get",
  });
  return data;
};

export const getStats = async () => {
  const { data } = await axiosInstance({
    method: "get",
    url: "/api/admin/stats",
  });
  return data;
};
