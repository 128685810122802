import React from "react";
import Tabs from "../../components/CustomTabs/CustomTabs.js";
import PromotionsTable from "./RegisterTable";

import { Icons } from "helpers/icons";

export const Register = () => {
    return (
        <Tabs
            title='Users:'
            headerColor='rose'
            tabs={[
                {
                    tabName: 'Register',
                    tabIcon: Icons.People,
                    tabContent: (
                        <PromotionsTable
                            api='promotions'
                            header='List of users promotions'
                        />
                    ),
                },
            ]}
        />
    );
};