import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Icons } from "helpers/icons";
import { configDate } from "helpers";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button";
import Pagination from "components/Pagination/Pagination";
import { Loader } from "components/Loader/Loader";

import {getTickets, updateTicketStatus} from "services/endpoints/tickets";
import {useQuery, useQueryClient} from "react-query";

const TicketsReports = ({filter}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [loading, setLoading] = useState(false);

  const scrollTarget = useRef(null);

  const history = useHistory();
  const { addToast } = useToasts();
  const queryClient = useQueryClient()

  const { data, error } = useQuery(
    ["getAllTickets", currentPage,filter],
    () => getTickets(currentPage,filter),
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (data) {
      setTotalPages(data.last_page);
      setLoading(false);
    } else {
      setLoading(true);
    }

    if (error) {
      setLoading(false);
      addToast("Something went wrong. Try reloading.", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 3000
      });
    }
  }, [data, error,addToast]);

  const resolveTicket = async (id,stat) => {
    const { error } = await updateTicketStatus(id, { status: stat });
    if (!error) {
      addToast("Status updated", { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000 });
      queryClient.invalidateQueries("getAllTickets");
    }
    if (error) {
      addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000 });
    }
  };

  const renderTicketTable = (arr) => {
    const ticketData = arr.map((ticket) => [
      `${ticket?.id}`,
      `${configDate(ticket?.created_at)}`,
      `${ticket?.subject}`,
      `${ticket?.body}`,
      `${ticket?.status === "resolved" ? "closed" : "open"}`,
      `${ticket?.user_profile?.user?.handle || '/'}`,
      <div style={{ float: "right" }}>
        {ticket.status === "in_progress" &&
            <Button
                color="danger"
                title="Mark as Resolved"
                onClick={() =>
                    resolveTicket(
                        ticket.id,
                        "resolved"
                    )
                }
            >
              <Icons.Clear />
            </Button>
        }
        <Button
          color="info"
          title="Check bug"
          onClick={() => history.push(`ticket/${ticket?.id}`)}
        >
          <Icons.RemoveRedEyeIcon />
        </Button>
      </div>,
    ]);

    return (
      <Table
        bugLink={true}
        tableHeaderColor="primary"
        tableHead={["ID", "Created on", "Subject", "Description","Status", "Opened by", ""]}
        tableData={[...ticketData]}
      />
    );
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardBody>
            {data && renderTicketTable(data.data)}
            {loading && <Loader />}
          </CardBody>
          {data && data.data.length > 0 && (
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
              loading={loading}
              scrollTarget={scrollTarget.current}
            />
          )}
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default TicketsReports;
