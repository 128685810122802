import { makeStyles } from "@material-ui/core/styles";

const styles = {
  cardTitlePrimary: {
    color: "#ff4443",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  commentImage: {
    display: "block",
    maxHeight: "100px",
    maxWidth: '50%',
    marginRight: "10px",
    "&:hover": {
      cursor: "zoom-in",
    },
  },
  cardCategoryPrimary: {
    "&,& a,& a:hover,& a:focus": {
      color: "#ff4443",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  bugBubbleGuest: {
    border: "1px solid #d3d3d3",
    width: "fit-content",
    borderRadius: "5px",
    padding: ".5rem 2rem .5rem 1rem",
    marginBottom: "1rem",
    "& span": {
      color: "grey",
      fontSize: ".75rem",
    },
  },
  bugBubbleAdminWrap: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  bugBubbleAdmin: {
    border: "1px solid #ff4443",
    backgroundColor: "#ff44431a",
    width: "fit-content",
    textAlign: "right",
    borderRadius: "5px",
    padding: ".5rem 1rem .5rem 2rem",
    marginBottom: "1rem",
    "& span": {
      color: "grey",
      fontSize: ".75rem",
    },
  },
};

export const useStyles = makeStyles(styles);
