import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useToasts } from "react-toast-notifications";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { useHistory, useParams } from "react-router-dom";
import Button from "components/CustomButtons/Button";
import { Icons } from "helpers/icons";
import Text from "./components/Text";
import User from "./components/User";
import Attachments from "./components/Attachments";
import { getDeletedMessageData } from "services/endpoints/messages";
import Restore from "views/MessagesReports/components/Restore";
import PermDelete from "./components/PermDelete";

export const DeletedMedia = (props) => {
  const [media, setMedia] = useState();
  const { addToast } = useToasts();
  const { id } = useParams();
  const history = useHistory();

  const {data, error} = useQuery(
    ["singleMessageData", id],
    () => getDeletedMessageData(id),
    { refetchOnWindowFocus: false }
  );

  const [deleteInput, setDeleteInput] = useState(false);
  const [restoreInput, setRestoreInput] = useState(false);
  
  useEffect(() => {
    if (data) {
      setMedia(data);
    }
    if (error) {
      addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000 });
    }
    // eslint-disable-next-line
  }, [data, error]);

  const deleteMedia = async (reason) => {
    try{
      history.push("/admin/deleted-media");
      addToast("Media permanently removed successfully", { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000 });
    } catch (e) {
      addToast("This media was already removed!", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000 });
    }
  }

  const restoreMedia = async () => {
    try{
      history.push("/admin/deleted-media");
      addToast("Media restored.", { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000 });
    } catch (e) {
      addToast("This media was not deleted!", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000 });
    }
  }

  const renderMedia = () => {
    if(media) {
      return (
        <>
          <GridContainer>
            {/* RETURN */}
            <GridItem xs={12} sm={12} md={12}>
              <Button color="primary" onClick={() => history.goBack()}>
                <Icons.ArrowBack /> Return
              </Button>
            </GridItem>

            {media.user_profile?.user && (
              <GridItem xs={12} sm={12} md={12}>
                <User
                  reportee={{
                    id: media.user_profile.user.id,
                    name: media.user_profile.user.name,
                    handle: media.user_profile.user.handle,
                  }}
                  sender={{
                    id: media.reportable.sender.user.id,
                    name: media.reportable.sender.user.name,
                    handle: media.reportable.sender.user.handle,
                  }}
                  reported_reason={media.reason}
                  time={media.created_at}
                />
              </GridItem>
            )}

            {media.reportable?.body && (
              <GridItem xs={12} sm={12} md={12}>
                <Text text={media.reportable.body} />
              </GridItem>
            )}

            {media.reportable?.attachments.length > 0 && (
              <GridItem xs={12} sm={12} md={12}>
                <Attachments attachments={media.reportable.attachments} />
              </GridItem>
            )}

            
            <GridItem xs={12} sm={12} md={12}>
              <Restore
                  approveMessage={restoreMedia }
                  approveInput={restoreInput}
                  setApproveInput={setRestoreInput}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <PermDelete
                deleteMessage={deleteMedia}
                deleteInput={deleteInput}
                setDeleteInput={setDeleteInput}
              />
            </GridItem>
          </GridContainer>
        </>
      );
    }
  }
    return (
        <div>{renderMedia()}</div>
      );
}