/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";

import { useQuery } from "react-query";
import { getStats } from "services/endpoints/dashboard";

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const { data } = useQuery("getStatsNumbers", () => getStats(), {
    refetchInterval: 30000,
  });

  const classes = useStyles();

  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }

  const { color, logo, image, logoText, routes } = props;

  const configBorderColor = (item) => {
    if(data) {
      if(activeRoute(item.layout + item.path)) {
        return "#FFF"
      } else {
        if (
            item.name === "Awaiting verification" &&
            data[item.name.toLowerCase().split(" ").join("_")] > 0
        ) {
          return "#4caf50";
        } else if (
            item.name === "Open Tickets" &&
            data[item.name.toLowerCase().split(" ").join("_")] > 0
        ) {
          return "#4caf50";
        }  else if (
            item.name === "Bug reports" &&
            data[item.name.toLowerCase().split(" ").join("_")] > 0
        ) {
          return "#ffff00";
        } else if (
            item.name === "Feature requests" &&
            data["feature_reports"] > 0
        ) {
          return "#10b7cc";
        } else if (
            item.name === "Reported Posts" &&
            data[item.name.toLowerCase().split(" ").join("_")] > 0
        ) {
          return "#ff0000";
        } else if (
            item.name === "Reported Media" &&
            data[item.name.toLowerCase().split(" ").join("_")] > 0
        ) {
          return "#ff0000";
        }  else if (
            item.name === "Reported Messages" &&
            data[item.name.toLowerCase().split(" ").join("_")] > 0
        ) {
          return "#ff0000";
        }
      }
    }
  };

  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        if (!prop.hidden) {
          var activePro = " ";
          var listItemClasses;
          listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop.layout + prop.path),
          });
          const whiteFontClasses = classNames({
            [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
          });
          return (
            <NavLink
              to={prop.layout + prop.path}
              className={activePro + classes.item}
              activeClassName="active"
              key={key}
            >
              <ListItem
                button
                className={classes.itemLink + listItemClasses}
                style={{
                  borderColor: configBorderColor(prop),
                }}
              >
                {typeof prop.icon === "string" ? (
                  <Icon
                    className={classNames(classes.itemIcon, whiteFontClasses)}
                  >
                    {prop.icon}
                  </Icon>
                ) : (
                  <prop.icon
                    className={classNames(classes.itemIcon, whiteFontClasses)}
                    style={{ color: configBorderColor(prop) }}
                  />
                )}
                <ListItemText
                  primary={prop.name}
                  className={classNames(classes.itemText, whiteFontClasses)}
                  disableTypography={true}
                />
                {data &&
                  (data[prop.name === "Tickets requests" ? "tickets_reports" : prop.name === "Feature requests" ? "feature_reports" :  prop.name.toLowerCase().split(" ").join("_")] ||
                    data[prop.name.toLowerCase().split(" ").join("_")] ===
                      0) && (
                    <div
                      className={classes.statsNumber}
                      style={{ color: configBorderColor(prop) }}
                    >
                      {data &&
                        data[prop.name === "Tickets requests" ? "tickets_reports" : prop.name === "Feature requests" ? "feature_reports" : prop.name.toLowerCase().split(" ").join("_")]}
                    </div>
                  )}
              </ListItem>
            </NavLink>
          );
        }
      })}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <div className={classNames(classes.logoLink)}>
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {logoText}
      </div>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          open={props.open}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <AdminNavbarLinks />
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};
