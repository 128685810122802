import React, { useEffect, useState, useRef } from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/postsStyle";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button";
import Pagination from "components/Pagination/Pagination";
import { Loader } from "components/Loader/Loader";

import { configDate } from "helpers";

import { getMessagesData } from "services/endpoints/messages";
import { useQuery } from "react-query";

const MessagesTable = ({ api, header, type }) => {
    const [messages, setMessages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState();

    const scrollTarget = useRef(null);

    const classes = useStyles();
    const history = useHistory();
    const { addToast } = useToasts();

    const { data, error } = useQuery(
        [`${api}`, currentPage, api],
        () => getMessagesData(currentPage, api),
        { refetchOnWindowFocus: false }
    );

    useEffect(() => {
        if (data) {
            setMessages(data);
            setTotalPages(data.last_page);
            setLoading(false);
        } else {
            setLoading(true);
        }

        if (error) {
            setLoading(false);
            addToast("Something went wrong. Try reloading.", {
                appearance: "error",
            });
        }

        // eslint-disable-next-line
    }, [data, error]);

    const renderMessagesTable = () => {
        if (messages && messages.data.length > 0) {
          if (type === "reports") {
                const messagesData = messages.data.map((message) => [
                    `${configDate(message.created_at)}`,
                    `${message.reason.split("_").join(" ")}`,
                    `${message.user_profile?.user?.name || ""} - @${
                        message.user_profile?.user?.handle || ""
                    }`,
                    <Button
                    color="info"
                    title="Check message"
                    onClick={() => {history.push(`message/preview/${message.reportable_id}`)}}
                    >
                        <RemoveRedEyeIcon />
                    </Button>,
                ]);
                return (
                    <Table
                        tableHeaderColor="primary"
                        tableHead={[
                            "Reported on",
                            "Reason",
                            "Report submitted by",
                            "",
                        ]}
                        tableData={[...messagesData]}
                    />
                );
            }
        } else {
            return <div>No new messages</div>;
        }
    };

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitlePrimary} ref={scrollTarget}>
                            {header}
                        </h4>
                    </CardHeader>

                    <CardBody>
                        {messages && renderMessagesTable()}
                        {loading && <Loader />}
                    </CardBody>

                    {messages && (
                        <Pagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPages={totalPages}
                            loading={loading}
                            scrollTarget={scrollTarget.current}
                        />
                    )}
                </Card>
            </GridItem>
        </GridContainer>
    );
};

export default MessagesTable;
