import React, { useState, useEffect } from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/usersStyle";
import { useToasts } from "react-toast-notifications";
import { configDateSimple, configDate } from "helpers";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { useQuery } from "react-query";
import {
  postUpdateProfileData,
  getProfileData,
} from "services/endpoints/users";

const ProfileData = ({
  id,
  setVerified,
  setProfileImages,
  setCreatorProfile,
  setBan
}) => {
  const [profile, setProfile] = useState();
  const [updates, setUpdates] = useState({});

  const classes = useStyles();
  const { addToast } = useToasts();

  const updateHandle = (data) => setUpdates({ ...updates, handle: data });
  const updateName = (data) => setUpdates({ ...updates, name: data });
  const updateLocation = (data) => setUpdates({ ...updates, location: data });
  const updateBio = (data) => setUpdates({ ...updates, bio: data });

  const updateProfile = async () => {
    const { data } = await postUpdateProfileData(id, updates);
    if (data) {
      addToast("Profile updated", { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000});
    }
    if (error) {
      addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000});
    }
  };

  const { data, error } = useQuery(
    ["getprofile", id],
    () => getProfileData(id),
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (data) {
      setProfile(data);
      setUpdates({
        handle: data.handle,
        name: data.name,
        location: data.profile.location,
        bio: data.profile.bio,
      });
      setVerified(!!data.profile.face_verified_at);
      setProfileImages({
        face_image_url: data.profile.face_image_url,
        id_image_url: data.profile.id_image_url,
        proof_image_url: data.profile.proof_image_url,
        birthday: data.profile.birthday,
      });
      setBan(data.banned)
      if (data.profile.creator_profile) {
        setCreatorProfile({
          isCreator: true,
          commission: data.profile.creator_profile.payout_percent,
        });
      }
      console.log(data)
    }
    if (error) {
      addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000});
    }
    // eslint-disable-next-line
  }, [data, error]);

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitlePrimary}>Edit Profile</h4>
      </CardHeader>
      {profile && (
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <a
                href={`https://www.fluffa.com/${profile.handle}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button color="info">View profile on Fluffa</Button>
              </a>
              <Button
                color="info"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://www.fluffa.com/${profile.handle}`
                  );
                  addToast("URL Copied to Clipboard", { appearance: "info" });
                }}
              >
                Copy URL
              </Button>
              <p>
                URL:
                <a
                    href={`https://www.fluffa.com/${profile.handle}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.profileFluffaLink}
                > {" "}
                  https://www.fluffa.com/{profile.handle}
                </a>
              </p>
              {profile.profile?.refferer_user?.creator_referral_link?.user_profile.user.handle ?
                  <p>
                    Referral link from:
                    <a
                        href={`https://www.fluffa.com/${profile.profile?.refferer_user?.creator_referral_link?.user_profile.user.handle}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.profileFluffaLink}
                    > {" "}
                      @{profile.profile?.refferer_user?.creator_referral_link?.user_profile.user.handle}
                    </a>
                  </p>:""
              }
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <CustomInput
                labelText="Created on"
                id="created_at"
                formControlProps={{ fullWidth: true }}
                inputProps={{ disabled: true }}
                val={`${
                  profile.created_at
                    ? configDate(profile.created_at)
                    : "Not created yet"
                }`}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <CustomInput
                labelText="Verified at"
                id="verified_at"
                formControlProps={{ fullWidth: true }}
                inputProps={{ disabled: true }}
                val={`${
                  profile.profile.face_verified_at
                    ? configDateSimple(profile.profile.face_verified_at)
                    : "Not verified yet"
                }`}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <CustomInput
                labelText="Approved at"
                id="approved_at"
                formControlProps={{ fullWidth: true }}
                inputProps={{ disabled: true }}
                val={`${
                  profile.created_at
                    ? configDate(profile.created_at)
                    : "Not approved yet"
                }`}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Email address"
                id="email-address"
                formControlProps={{ fullWidth: true }}
                inputProps={{ disabled: true }}
                val={`${profile.email}`}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Username"
                id="handle"
                formControlProps={{ fullWidth: true }}
                val={`${updates.handle}`}
                updateState={updateHandle}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Name"
                id="first-name"
                formControlProps={{ fullWidth: true }}
                val={`${updates.name}`}
                updateState={updateName}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Birthday"
                id="birthday"
                formControlProps={{ fullWidth: true }}
                inputProps={{ disabled: true }}
                val={`${profile.birthday ? profile.birthday : "Not specified"}`}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Location"
                id="location"
                formControlProps={{ fullWidth: true }}
                val={`${updates.location ? updates.location : "Not specified"}`}
                updateState={updateLocation}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Short bio"
                id="bio"
                formControlProps={{ fullWidth: true }}
                inputProps={{ multiline: true, rows: 3 }}
                val={`${updates.bio}`}
                updateState={updateBio}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      )}
      <CardFooter>
        <Button color="success" onClick={updateProfile}>
          Update Profile
        </Button>
      </CardFooter>
    </Card>
  );
};

export default ProfileData;
