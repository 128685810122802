import React from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/postsStyle";
import { Link } from "react-router-dom";
import { configDate } from "helpers";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

const User = ({ id, name, handle, time }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitlePrimary}>Info</h4>
      </CardHeader>
      <CardBody>
        <p>
          User:{" "}
          <Link className={classes.userProfileLink} to={`/admin/profile/${id}`}>
            {name} - @{handle}
          </Link>
        </p>
        <p>Created on: {configDate(time)}</p>
      </CardBody>
    </Card>
  );
};

export default User;
