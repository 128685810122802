import React, {useState} from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/usersStyle";
import { useToasts } from "react-toast-notifications";
import axiosInstance from "api/axiosInstance";
import { useHistory } from "react-router-dom";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

const BanProfile = ({ id,ban,setBan }) => {
  const classes = useStyles();
  const history = useHistory();
  const { addToast } = useToasts();

  const banProfile = (action) => {
    axiosInstance({
      method: "post",
      url: `/api/admin/users/${id}/ban${!action ? "?action=0" : ""}`,
    })
      .then((resp) =>
            addToast(`User ${!action ? "unbanned" : "banned"} successfully`, { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000}),
            setBan(!action ? 0 : 1)
      )
      .catch((err) =>
        addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000})
      );
  };

  const deleteProfile = () => {
    axiosInstance({
      method: "post",
      url: `/api/admin/users/${id}/delete`,
    })
      .then((resp) =>
          addToast("User deleted successfully", { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000}),
          history.push("/admin/all-users")
      )
      .catch((err) =>
        addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000})
      );
  };

  const [enableDelete, setEnableDelete] = useState(false)

  return (
    <Card>
      <CardHeader color="warning">
        <h4 className={classes.cardTitleWhite}>Ban/Delete profile</h4>
      </CardHeader>
      <CardBody>
        {enableDelete ?
          <>
            <Button color="primary" onClick={() => deleteProfile()}>
              Confirm
            </Button>
            <Button color="warning" onClick={() => setEnableDelete(false)}>
              X
            </Button>
          </>
        :
          <>
            <Button color="warning" onClick={() => banProfile(ban === 1 ? false : true)}>
              {ban === 1 ? "Unban" : "Ban"} Profile
            </Button>
            <Button color="warning" onClick={() => setEnableDelete(true)}>
              Delete Profile
            </Button>
          </>
        }
      </CardBody>
    </Card>
  );
};
export default BanProfile;
