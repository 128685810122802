import React, { useEffect, useState, useRef } from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/postsStyle";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button";
import Pagination from "components/Pagination/Pagination";
import { Loader } from "components/Loader/Loader";

import { configDate } from "helpers";

import { getPostsData } from "services/endpoints/posts";
import { useQuery } from "react-query";

const Posts = ({ api, header, type }) => {
  const [posts, setPosts] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState();

  const scrollTarget = useRef(null);

  const classes = useStyles();
  const history = useHistory();
  const { addToast } = useToasts();

  const { data, error } = useQuery(
    [`${api}`, currentPage, api],
    () => getPostsData(currentPage, api),
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (data) {
      setPosts(data);
      setTotalPages(data.last_page);
      setLoading(false);
    } else {
      setLoading(true);
    }

    if (error) {
      setLoading(false);
      addToast("Something went wrong. Try reloading.", {
        appearance: "error",
      });
    }

    // eslint-disable-next-line
  }, [data, error]);

  const renderPostsTable = () => {
    if (posts && posts.data.length > 0) {
      if (type === "posts") {
        const postsData = posts.data.map((post) => [
          `${post.id}`,
          `${post.user_profile?.user?.handle || ""}`,
          `${configDate(post.created_at)}`,
          `${post.text ? post.text : "✗"}`,
          `${post.post_images && post.post_images.length > 0 ? "✓" : "✗"}`,
          `${post.post_videos && post.post_videos.length > 0 ? "✓" : "✗"}`,
          <div style={{ float: "right" }}>
            <Button
              color="info"
              title="Check post"
              onClick={() => history.push(`preview/${post.id}`)}
            >
              <RemoveRedEyeIcon />
            </Button>
          </div>,
        ]);
        return (
          <Table
            postLink={true}
            tableHeaderColor="primary"
            tableHead={[
              "Id",
              "User",
              "Created on",
              "Text",
              "Images",
              "Videos",
              "",
            ]}
            tableData={[...postsData]}
          />
        );
      } else if (type === "reports") {
        const postsData = posts.data.map((post) => [
          `${post.reportable.id}`,
          `${configDate(post.created_at)}`,
          `${post.reportable.text ? post.reportable.text : "" }`,
          `${post.reason.split("_").join(" ")}`,
          `@${
            post.user_profile?.user?.handle || ""
          }`,
          `@${
              post.reportable.user_profile?.user?.handle || ""
          }`,
          <div style={{ float: "right" }}>
            <Button
              color="info"
              title="Check post"
              onClick={() => history.push(`reports/preview/${post.reportable_id}`)}
            >
              <RemoveRedEyeIcon />
            </Button>
          </div>,
        ]);
        return (
          <Table
            tableHeaderColor="primary"
            tableHead={[
              "Id",
              "Reported on",
              "Text",
              "Reason",
              "Reported by",
              "Reported user",
              "",
            ]}
            tableData={[...postsData]}
          />
        );
      } else if (type === 'deleted') {
        const postsData = posts.data.map((post) => [
          `${post.id}`,
          `${configDate(post.reportable.deleted_at)}`,
          `${post.reportable.text ? post.reportable.text : "/"}`,
          `${post.reason.split("_").join(" ")}`,
          `${post.user_profile?.user?.name || ""} - @${
            post.user_profile?.user?.handle || ""
          }`,
          <div style={{ float: "right" }}>
            <Button
              color="info"
              title="Check post"
              onClick={() => history.push(`deleted/preview/${post.id}`)}
            >
              <RemoveRedEyeIcon />
            </Button>
          </div>,
        ]);
        return (
          <Table
            tableHeaderColor="primary"
            tableHead={[
              "Id",
              "Deleted at",
              "Text",
              "Reason",
              "Reported by",
              "",
            ]}
            tableData={[...postsData]}
          />
        );
      } else {
      }
      return <div>No new posts</div>;
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitlePrimary} ref={scrollTarget}>
              {header}
            </h4>
          </CardHeader>

          <CardBody>
            {posts && renderPostsTable()}
            {loading && <Loader />}
          </CardBody>

          {posts && (
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
              loading={loading}
              scrollTarget={scrollTarget.current}
            />
          )}
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Posts;
