import axiosInstance from "api/axiosInstance";

export const getPostsData = async (page = 1, api) => {
  const { data } = await axiosInstance({
    method: "get",
    url: `/api/${api}?page=${page}`,
  });

  return data;
};

export const getReportedPosts = async (page) => {
  const { data } = await axiosInstance({
    method: "get",
    url: `/api/admin/reports/posts?page=${page}`,
  });
  return data;
};

export const getSinglePostData = async (id) => {
  const { data } = await axiosInstance({
    method: "get",
    url: `/api/admin/posts/${id}`,
  });
  return data;
};

export const getReportedPostData = async (id) => {
  const { data } = await axiosInstance({
    method: "get",
    url: `/api/admin/posts/reported/${id}`,
  });
  return data;
};

export const getSingleDeletedPostData = async (id) => {
  const { data } = await axiosInstance({
    method: "get",
    url: `/api/admin/posts/deleted/${id}`,
  });
  return data;
};

export const getSinglePostReports = async (page, id) => {
  const { data } = await axiosInstance({
    method: "get",
    url: `/api/admin/posts/${id}/reports?page=${page}`,
  });
  return data;
};

export const deleteSinglePost = async (id, reason) => {
  const { data } = await axiosInstance({
    method: "post",
    url: `/api/admin/remove-reported`,
    data: {reason: reason, post_ids: [id] },
  });
  return data;
};

export const permanentlyDeleteSinglePost = async (id) => {
  const { data } = await axiosInstance({
    method: "delete",
    url: `/api/admin/posts/deleted`,
    data: { post: id },
  });
  return data;
};

export const approveSinglePost = async (id) => {
  const { data } = await axiosInstance({
    method: "put",
    url: `api/admin/posts/visible/${id}`,
  });
  return data;
};

export const ignoreSinglePost = async (id) => {
  const { data } = await axiosInstance({
    method: "delete",
    url: `/api/admin/dismiss-reported`,
    data: {type: 'Post', report_ids: [id]}
  });
  return data;
};

export const restoreDeletedSinglePost = async (id) => {
  const { data } = await axiosInstance({
    method: "post",
    url: `api/admin/posts/restore`,
    data: {post: id},
  });
  return data;
};

export const GetDeletedPosts = async (id) => {
  const { data } = await axiosInstance({
    method: "get",
    url: `api/admin/posts/get/deleted-reports`,
  });
  return data;
};

