import React from "react";
import { useStyles } from "assets/jss/material-dashboard-react/components/paginationStyle";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button";
import { Loader } from "components/Loader/Loader";

const Pagination = ({
  currentPage,
  setCurrentPage,
  totalPages,
  loading,
  scrollTarget = null,
}) => {
  const classes = useStyles();

  const prevPage = () =>
    parseInt(currentPage) > 1 && setCurrentPage(parseInt(currentPage) - 1);
  const nextPage = () =>
    parseInt(currentPage) < totalPages &&
    setCurrentPage(parseInt(currentPage) + 1);

  const createPaginationNumbers = () => {
    return [...Array(totalPages).keys()].map((e) => (
      <Button
        key={e}
        color="primary"
        style={{ padding: "8px 12px", minHeight: "43px" }}
        onClick={() => {
          scrollTarget && scrollTarget.scrollIntoView();
          setCurrentPage(e + 1);
        }}
        className={currentPage === e + 1 ? classes.paginationButton : ""}
      >
        {e + 1}
      </Button>
    ));
  };

  return (
    <CardBody>
      <div style={{ display: "flex" }}>
        <Button
          color="primary"
          onClick={(e) => {
            prevPage();
            scrollTarget && scrollTarget.scrollIntoView();
          }}
        >
          Prev
        </Button>
        <div
          className={!loading ? classes.paginationButtonsContainer : undefined}
        >
          {createPaginationNumbers()}
        </div>
        <Button
          color="primary"
          onClick={() => {
            nextPage();
            scrollTarget && scrollTarget.scrollIntoView();
          }}
        >
          Next
        </Button>
        <div>{loading && <Loader />}</div>
      </div>
    </CardBody>
  );
};

export default Pagination;
