import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Icons } from "helpers/icons";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";

import Ban from "./components/Ban";
import Commission from "./components/Commission";
import Transactions from "./components/Transactions";
import Logins from "./components/Logins";
import AutoApprove from "./components/AutoApprove";
// import Stats from "./components/Stats";
import ImageVerification from "./components/ImageVerification";
import Notes from "./components/Notes";
import ProfileData from "./components/ProfileData";

import { useParams } from "react-router-dom";

export default function Profile() {
  const { id } = useParams();
  const [verified, setVerified] = useState(false);
  const [profileImages, setProfileImages] = useState({
    face_image_url: null,
    id_image_url: null,
    proof_image_url: null,
    birthday: null,
  });
  const [creatorProfile, setCreatorProfile] = useState({
    isCreator: false,
    commission: 80,
  });
  const [ban,setBan] = useState(0)
  const history = useHistory();

  return (
    <GridContainer>
      {/* RETURN */}
      <GridItem xs={12} sm={12} md={12}>
        <Button color="primary" onClick={() => history.goBack()}>
          <Icons.ArrowBack /> Return
        </Button>
      </GridItem>

      {/* IMAGE VERIFICATION */}
      <GridItem xs={12} sm={12} md={12}>
        <ImageVerification
          id={id}
          verified={verified}
          setVerified={(data) => setVerified(data)}
          profileImages={profileImages}
        />
      </GridItem>

      {/* COMMISSION PERCENTAGE FORM */}
          <GridItem xs={12} sm={12} md={12}>
             <AutoApprove />
          </GridItem>

      {/* PROFILE FORM */}
      <GridItem xs={12} sm={12} md={12}>
        <ProfileData
          id={id}
          setVerified={(data) => setVerified(data)}
          setProfileImages={(data) => setProfileImages(data)}
          setCreatorProfile={(data) => setCreatorProfile(data)}
          setBan={(data) => setBan(data)}
        />
      </GridItem>

      {/* LOGIN TIMES */}
      <GridItem xs={12} sm={12} md={8}>
        <Logins id={id} />
      </GridItem>

      {/* COMMISSION PERCENTAGE FORM */}
      {creatorProfile.isCreator && (
        <GridItem xs={12} sm={12} md={4}>
          <Commission id={id} percent={creatorProfile.commission} />
        </GridItem>
      )}

      {/* EARNINGS/SPENDINGS TABLES */}
      {creatorProfile.isCreator && (
        <GridItem xs={12} sm={12} md={12}>
          <Transactions id={id} type={"earnings"} />
        </GridItem>
      )}
      <GridItem xs={12} sm={12} md={12}>
        <Transactions id={id} type={"spending"} />
      </GridItem>

      {/* NOTES */}
      <GridItem xs={12} sm={12} md={12}>
        <Notes id={id} />
      </GridItem>

      {/* BAN/DELETE PROFILE */}
      <GridItem xs={12} sm={12} md={12}>
        <Ban id={id} ban={ban} setBan={setBan} />
      </GridItem>
    </GridContainer>
  );
}
