import React from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/postsStyle";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

const Restore = ({ approveMessage, approveInput, setApproveInput }) => {
    const classes = useStyles();

    return (
        <Card>
            <CardHeader color="warning">
                <h4 className={classes.cardTitleWhite}>Restore media</h4>
            </CardHeader>
            <CardBody>
                {approveInput ? (
                    <>
                        <Button color="primary" onClick={() => approveMessage()}>
                            Confirm
                        </Button>
                        <Button color="warning" onClick={() => setApproveInput(false)}>
                            X
                        </Button>
                    </>
                ) : (
                    <Button color="warning" onClick={() => setApproveInput(true)}>
                        Restore
                    </Button>
                )}
            </CardBody>
        </Card>
    );
};

export default Restore;
