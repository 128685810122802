import React, { useState,useCallback } from "react";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import { setAccessToken } from "../../api/storage";
import { timeSetup } from "../../api/timeSetup";
import { useHistory } from "react-router-dom";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import Primary from "components/Typography/Primary";

import logo from "../../assets/img/fluffa-logo.png";

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';

const Login = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const history = useHistory();
  const { addToast } = useToasts();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [captcha_token, setCaptcha] = useState("");
  const [error, setError] = useState(false);

  const recaptcha = useCallback(
    (token) => {
      setCaptcha(token)
    },
    [],
);

  const submitForm = async () => {
    axios({
      method: "post",
      url: `${apiUrl}/api/auth/login`,
      data: { email, password, captcha_token },
    })
      .then((response) => {
        if (response.data.admin) {
          timeSetup(response.data.expires_in);
          setAccessToken(response.data.access_token);
          history.goBack()
          // window.location.reload()
        } else {
          addToast("Not admin!", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000 });
        }
      })
      .catch((error) => {
        setError(true);
        addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000 });
      });
  };

  const updateEmail = (data) => setEmail(data);
  const updatePass = (data) => setPassword(data);

  return (
    <GridContainer home="home">
      <GridItem style={{ textAlign: "center" }} xs={12} sm={12} md={6}>
        <img src={logo} alt="Fluffa logo" />
      </GridItem>
      <div>
        <GoogleReCaptchaProvider reCaptchaKey="6LfuNvUaAAAAAH64PNXyAsS5aIHVGdB7SCsaDi5U">
          <GoogleReCaptcha  onVerify={recaptcha} />
        </GoogleReCaptchaProvider>
      </div>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="primary">Log in</CardHeader>
          <CardBody>
            <CustomInput
              labelText="Email"
              id="email"
              formControlProps={{ fullWidth: true }}
              updateState={updateEmail}
            />
            <CustomInput
              labelText="Password"
              id="password"
              formControlProps={{ fullWidth: true }}
              inputProps={{ type: "password" }}
              updateState={updatePass}
            />
            {error && <Primary>Invalid email or password.</Primary>}
            <Button color="primary" type="button" onClick={submitForm}>
              Log in
            </Button>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Login;
