import axiosInstance from "api/axiosInstance";

export const getMessagesData = async (page = 1, api) => {
    const { data } = await axiosInstance({
        method: "get",
        url: `/api/${api}?page=${page}`,
    });

    return data;
};

export const getMediaData = async (page = 1, api) => {
  const { data } = await axiosInstance({
      method: "get",
      url: `/api/${api}?page=${page}`,
  });

  return data;
};



export const getSingleMessageData = async (id) => {
    const { data } = await axiosInstance({
        method: "get",
        url: `/api/admin/messages/${id}/reports`,
      });
    return data;
};

export const getDeletedMessageData = async (id) => {
  const { data } = await axiosInstance({
      method: "get",
      url: `/api/admin/media/get-deleted/${id}`,
    });
  return data;
};

export const getSingleMessageReports = async (page, id) => {
  const { data } = await axiosInstance({
    method: "get",
    url: `/api/admin/messages/${id}/reports?page=${page}`,
  });
  return data;
};

export const deleteSingleMessage = async (id, reason) => {
    const { data } = await axiosInstance({
        method: "post",
        url: `/api/admin/remove-reported-message`,
        data: {reason: reason, type: 'ChatMessage', report_ids: [id]}
      });
    return data;
} 

export const deleteSingleAttachment = async (id, reason) => {
    const { data } = await axiosInstance({
        method: "post",
        url: `/api/admin/remove-reported-message`,
        data: {reason: reason, type: 'ChatMessageAttachment', report_ids: [id]}
      });
    return data;
}

export const permanentlyRemoveAttachment = async (id) => {
  const { data } = await axiosInstance({
      method: "delete",
      url: `/api/admin/media/deleted`,
      data: { report: id }
    });
  return data;
}

export const restoreSingleMessage = async (id) => {
  const { data } = await axiosInstance({
      method: "post",
      url: `/api/admin/media/deleted/restore`,
      data: {report: id}
    });
  return data;
} 

export const approveSingleMessage = async (id) => {
    const { data } = await axiosInstance({
        method: "delete",
        url: `/api/admin/dismiss-reported`,
        data: {type: 'ChatMessage', report_ids: [id]}
      });
    return data;
} 

export const GetDeletedMedia = async (id) => {
  const { data } = await axiosInstance({
    method: "get",
    url: `/api/admin/media/get/deleted-reports`,
  });
  return data;
};