import React from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/postsStyle";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

const Images = ({ images }) => {
    const classes = useStyles();

    return (
        <Card>
            <CardHeader color="primary">
                <h4 className={classes.cardTitlePrimary}>Images</h4>
            </CardHeader>
            <CardBody>
                {images.map((e) => (
                    <a href={e} target="_blank" key={e} rel="noopener noreferrer">
                        <img
                            className={classes.postImage}
                            src={e}
                            key={e}
                            alt="Post upload"
                        />
                    </a>
                ))}
            </CardBody>
        </Card>
    );
};

export default Images;
