import React, { useEffect, useState, useRef } from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/postsStyle";
import { useToasts } from "react-toast-notifications";
import CustomInput from "../../components/CustomInput/CustomInput";
import moment from "moment";
import "./promotionsTable.css";

import { Icons } from "helpers/icons";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button";
import Pagination from "components/Pagination/Pagination";
import { Loader } from "components/Loader/Loader";

import { getPromotionsData, postPromotion, deletePromotion } from "services/endpoints/promotions";
import { useQuery,useQueryClient } from "react-query";
import axiosInstance from "api/axiosInstance";

const Promotions = ({ api, header }) => {
    const [promotions, setPromotions] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState();

    const scrollTarget = useRef(null);

    const classes = useStyles();
    const { addToast } = useToasts();
    const queryClient = useQueryClient();

    const { data, error } = useQuery(
        [`${api}`, currentPage, api],
        () => getPromotionsData(currentPage, api),
        { refetchOnWindowFocus: false }
    );

    useEffect(() => {
        if (data) {
            setPromotions(data);
            setTotalPages(data.last_page);
            setLoading(false);
        } else {
            setLoading(true);
        }

        if (error) {
            setLoading(false);
            addToast("Something went wrong. Try reloading.", {
                appearance: "error",
            });
        }
    }, [data, error,addToast]);

    const renderPromotionsTable = () => {
        if (promotions && promotions.data.length > 0) {
            const promotionsData = promotions.data.map((promotion) => [
                `${promotion.id}`,
                `${promotion.userProfile.handle}`,
                `${promotion.days_count}`,
                `${moment(promotion.created_at).format("D MMMM, YYYY")}`,
                `${moment(promotion.created_at).add("days", Number(promotion.days_count)).format("D MMMM, YYYY")}`
                ,
                <div style={{ float: "right" }}>
                    <Button
                        className="user-verify-button"
                        color={"primary"}
                        title="Verify profile"
                        onClick={() => {
                            deletePromotionFunction(promotion.id)
                        }}
                    >
                        <Icons.Clear />
                    </Button>
                </div>,
            ]);
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={[
                        "Id",
                        "User",
                        "Days of promotion",
                        "Start",
                        "End",
                        "",
                    ]}
                    tableData={[...promotionsData]}
                />
            );
        } else {
            return <div>No promotions</div>
        }
    };


    const [users, setUsers] = useState([]);
    const [search,setSearch] = useState({
        user: "",
        id: 0
    })
    const [days, setDays] = useState(1);

    const getUsers = async (search) => {
        const { data } = await axiosInstance({
            method: "get",
            url: `/api/admin/users?term=${search}`,
        });
        setUsers(data.data)
    }

    useEffect(() => {
        getUsers(search.user)
    }, [search.user]);

    const updateSearch = (data) => setSearch({
        user: data
    });

    const updateDays = (data) => setDays(data);

    const [focus,setFocus] = useState(false)

    const controlsRef = useRef(null);

    useEffect(() => {
        const controlsClick = (e:MouseEvent) => {
            if(controlsRef.current && e.target !== null){
                //@ts-ignore
                if(e.target !== controlsRef.current && !controlsRef.current.contains(e.target)){
                    setFocus(false)
                }
            }
        }
        window.addEventListener("click", controlsClick);
        return () => {
            window.removeEventListener("click", controlsClick);
        }
    },[]);

    const selectUser = (handle,id) => {
        setSearch({user:handle, id:id})
        setFocus(false)
    }

    const storePromotion = async () => {
        const { data, error } = await  postPromotion(search.id,days);
        if (data) {
            setSearch({user:"", id:0})
            setDays(1)
            addToast("New promotion ADDED", {appearance: "success"});
            queryClient.invalidateQueries(`${api}`);
        }
        if (error) {
            addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000});
        }
    }

    const deletePromotionFunction = async (id) => {
        const { error } = await  deletePromotion(id);
        if (!error) {
            addToast("Promotion DELETED", {appearance: "success"});
            queryClient.invalidateQueries(`${api}`);
        }
        if (error) {
            addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000});
        }
    }
    
    return (
        <GridContainer>

            <GridItem xs={12} sm={12} md={12} >
                <Card>
                    <CardHeader color="primary">Add promotions</CardHeader>
                    <CardBody>
                            <GridContainer>
                                    <GridItem xs={12} sm={12} md={5}>
                                        <div className="input-search-user" ref={controlsRef}>
                                            <CustomInput
                                                labelText="Select user"
                                                id="select-user"
                                                formControlProps={{ fullWidth: true }}
                                                val={`${search.user}`}
                                                updateState={updateSearch}
                                                updateFocus={setFocus}
                                            />
                                            {users.length > 0 && focus ?
                                                <div className={"promotions-search-list"}>
                                                    {users.map(value => (
                                                        <span className={`${promotions?.data.filter(prom => prom.id === value.id).length > 0 ? "selected-user" : ""}`} key={value.id} onClick={() => selectUser(value.handle,value.id)}>{value.handle}</span>
                                                    ))}
                                                </div> : ""
                                            }
                                            {search.user.length > 0 && search.id > 0 ?
                                                <div className={`remove-user-search`} onClick={() => setSearch({user:"", id:0}) }> <Icons.Clear /> </div>
                                                : ""
                                            }
                                        </div>
                                    </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                    <CustomInput
                                        labelText="Days of promotion"
                                        id="days-promotion"
                                        formControlProps={{ fullWidth: true }}
                                        val={`${days}`}
                                        updateState={updateDays}
                                    />
                                </GridItem>
                                <div className={"create-promotion-button"}>
                                     <Button color="success" onClick={() => storePromotion()}>
                                       Create
                                     </Button>
                                </div>
                            </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitlePrimary} ref={scrollTarget}>
                            {header}
                        </h4>
                    </CardHeader>

                    <CardBody>
                        {promotions && renderPromotionsTable()}
                        {loading && <Loader />}
                    </CardBody>

                    {promotions && (
                        <Pagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPages={totalPages}
                            loading={loading}
                            scrollTarget={scrollTarget.current}
                        />
                    )}
                </Card>
            </GridItem>
        </GridContainer>
    );
};

export default Promotions;
