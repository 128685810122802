/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { Icons } from "helpers/icons";

import Dash from "views/Dashboard/Dash.js";
import { ToBeVerified, AllUsers, Creators, RejectedUsers, BannedUsers, VerifiedEmail,UnverifiedUsers,DeactivatedUsers} from "views/Users/UsersPages";
import { Register } from "views/Register/RegisterPage"
import { Promotions } from "views/Promotions/PromotionsPage"
import Profile from "views/Profile/Profile.js";
import {
  AllPosts,
  ReportedPosts,
  UnapprovedPosts,
  // BlockedPosts
} from "views/Posts/PostsPages";
import {
  ReportedMessages,
  ReportedMedia,
} from "views/MessagesReports/MessagesPages";
import Post from "views/Posts/Post.js";
import Logout from "views/Login/Logout.js";
import BugReports from "views/BugReports/BugReports"
import FeatureReports from "views/FeatureReports/FeatureReports"
import BugPage from "views/BugReports/BugPage";
import FeaturePage from "views/FeatureReports/FeaturePage";
import { OpenTicket,ClosedTickets } from "views/TicketsReports/TicketsPages";
import TicketPage from "views/TicketsReports/TicketsPage"
import RestrictedWords from "views/RestrictedWords/RestrictedWords"
import { MessagesView } from "views/MessagesReports/Messages";
import { MediaView } from "views/MessagesReports/Media";
import { DeletedMedias } from "views/MessagesReports/MessagesPages";
import { DeletedPosts } from "views/Posts/PostsPages";
import DeletedPost from "views/Posts/DeletedPost.js";
import { DeletedMedia } from "views/MessagesReports/DeletedMedia";

const dashboardRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: Icons.Dashboard,
    component: Dash,
    layout: '/admin',
  },
  // USERS
  {
    path: '/awaiting-verification',
    name: 'Awaiting verification',
    icon: Icons.VerifiedUser,
    component: ToBeVerified,
    layout: '/admin',
  },
  {
    path: '/creators',
    name: 'Creators',
    icon: Icons.LinkedCameraIcon,
    component: Creators,
    layout: '/admin',
  },
  {
    path: '/users',
    name: 'Users',
    icon: Icons.Person,
    component: VerifiedEmail,
    layout: '/admin',
  },
  {
    path: '/unverified-users',
    name: 'Unverified users',
    icon: Icons.Person,
    component: UnverifiedUsers,
    layout: '/admin',
  },
  {
    path: '/all-users',
    name: 'All users',
    icon: Icons.People,
    component: AllUsers,
    layout: '/admin',
  },
  {
    path: '/create-user',
    name: 'Create user',
    icon: Icons.People,
    component: Register,
    layout: '/admin',
  },
  {
    path: '/banned-users',
    name: 'Banned users',
    icon: Icons.Block,
    component: BannedUsers,
    layout: '/admin',
  },
  {
    path: '/rejected-users',
    name: 'Rejected users',
    icon: Icons.RemoveCircleOutlineIcon,
    component: RejectedUsers,
    layout: '/admin',
  },
  {
    path: '/deativated-users',
    name: 'Deactivated users',
    icon: Icons.RemoveCircleOutlineIcon,
    component: DeactivatedUsers,
    layout: '/admin',
  },
  {
    path: '/open-tickets',
    name: 'Open Tickets',
    icon: Icons.FiberNewIcon,
    component: OpenTicket,
    layout: '/admin',
  },
  {
    path: '/closed-tickets',
    name: 'Closed Tickets',
    icon: Icons.Close,
    component: ClosedTickets,
    layout: '/admin',
  },
  {
    path: '/bug-reports',
    name: 'Bug reports',
    icon: Icons.BugReport,
    component: BugReports,
    layout: '/admin',
  },
  {
    path: '/feature-reports',
    name: 'Feature requests',
    icon: Icons.FiberNewIcon,
    component: FeatureReports,
    layout: '/admin',
  },
  {
    path: '/promotions',
    name: 'Promotions',
    icon: Icons.LocalAtm,
    component: Promotions,
    layout: '/admin',
  },
  {
    path: '/posts',
    name: 'All Posts',
    icon: Icons.PermMedia,
    component: AllPosts,
    layout: '/admin',
  },
  {
    path: '/unapproved-posts',
    name: 'Unapproved Posts',
    icon: Icons.RemoveCircleOutlineIcon,
    component: UnapprovedPosts,
    layout: '/admin',
  },
  {
    path: '/reported-posts',
    name: 'Reported Posts',
    icon: Icons.Warning,
    component: ReportedPosts,
    layout: '/admin',
  },
  {
    path: '/deleted-posts',
    name: 'Deleted Posts',
    icon: Icons.ThumbDown,
    component: DeletedPosts,
    layout: '/admin',
  },
  {
    path: '/reported-media',
    name: 'Reported Media',
    icon: Icons.Warning,
    component: ReportedMedia,
    layout: '/admin',
  },
  {
    path: '/deleted-media',
    name: 'Deleted Media',
    icon: Icons.Warning,
    component: DeletedMedias,
    layout: '/admin',
  },
  {
    path: '/reported-messages',
    name: 'Reported Messages',
    icon: Icons.Warning,
    component: ReportedMessages,
    layout: '/admin',
  },
  {
    path: '/restricted-words',
    name: 'Restricted Words',
    icon: Icons.Block,
    component: RestrictedWords,
    layout: '/admin',
  },
  // {
  //   path: '/search-users',
  //   name: 'Search users',
  //   icon: Icons.People,
  //   component: SearchUsers,
  //   layout: '/admin',
  // },
  {
    path: '/profile',
    name: 'Profile',
    icon: Icons.Person,
    component: Profile,
    layout: '/admin',
    id: 'id',
    hidden: true,
  },
  // POSTS
  // {
  //   path: "/blocked-posts",
  //   name: "Blocked Posts",
  //   icon: Block,
  //   component: BlockedPosts,
  //   layout: "/admin",
  // },
  {
    path: '/preview',
    name: 'Post',
    icon: Icons.Panorama,
    component: Post,
    layout: '/admin',
    id: 'id',
    hidden: true,
  },
  {
    path: '/reports/preview',
    name: 'Reported Post',
    icon: Icons.ThumbDown,
    component: Post,
    layout: '/admin',
    id: 'id',
    hidden: true,
  },
  {
    path: '/deleted/preview',
    name: 'Deleted post',
    icon: Icons.Panorama,
    component: DeletedPost,
    layout: '/admin',
    id: 'id',
    hidden: true,
  },
  {
    path: '/message/preview',
    name: 'Message',
    icon: Icons.Panorama,
    component: MessagesView,
    layout: '/admin',
    id: 'id',
    hidden: true,
  },
  {
    path: '/media/preview',
    name: 'Message',
    icon: Icons.Panorama,
    component: MediaView,
    layout: '/admin',
    id: 'id',
    hidden: true,
  },
  {
    path: '/media/deleted/preview',
    name: 'Message',
    icon: Icons.Panorama,
    component: DeletedMedia,
    layout: '/admin',
    id: 'id',
    hidden: true,
  },
  {
    path: '/problem',
    name: 'Bug page',
    icon: Icons.BugReport,
    component: BugPage,
    layout: '/admin',
    id: 'id',
    hidden: true,
  },
  {
    path: '/feature',
    name: 'Feature page',
    icon: Icons.FiberNewIcon,
    component: FeaturePage,
    layout: '/admin',
    id: 'id',
    hidden: true,
  },
  {
    path: '/ticket',
    name: 'Ticket page',
    icon: Icons.FiberNewIcon,
    component: TicketPage,
    layout: '/admin',
    id: 'id',
    hidden: true,
  },
  {
    path: '/logout',
    name: 'Logout',
    icon: Icons.ArrowBack,
    component: Logout,
    layout: '/admin',
  },
];

export default dashboardRoutes;
