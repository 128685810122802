import React from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/dashboardStyle";

import { Link } from "react-router-dom";

import CardIcon from "components/Card/CardIcon";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";

import Update from "@material-ui/icons/Update";

const TopTotalBlock = (props) => {
  const { link, icon, color, text, stats } = props;
  const now = new Date();
  const classes = useStyles();

  return (
    <Link to={link}>
      <Card>
        <CardHeader color={`${color}`} stats icon>
          <CardIcon color={`${color}`}>{icon}</CardIcon>
          <p className={classes.cardCategory}>Total {text}</p>
          <h3 className={classes.cardTitle}>{stats}</h3>
        </CardHeader>
        <CardFooter stats>
          <div className={classes.stats}>
            <Update />
            Updated at {now.toLocaleTimeString()}
          </div>
        </CardFooter>
      </Card>
    </Link>
  );
};

export default TopTotalBlock;
