import { makeStyles } from "@material-ui/core/styles";

const styles = {
  cardTitlePrimary: {
    color: "#ff4443",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  cardCategoryPrimary: {
    "&,& a,& a:hover,& a:focus": {
      color: "#ff4443",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#fff",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  profileImage: {
    maxWidth: "100%",
    cursor: "zoom-in",
  },
  profileFluffaLink: {
    color: "#00ACC1",
    fontWeight: "400",
  },
  verificationPopup: {
    background: "#fff",
    position: "absolute",
    right: "14rem",
    padding: "1rem",
    borderRadius: "5px",
    width: "calc(100% - 270px)",
    display: "flex",
    zIndex: "5",
    maxWidth: "750px",
    "& img": {
      maxHeight: "200px",
    },
  },
  verificationPopupVerified: {
    border: "1px solid #4caf50",
  },
  verificationPopupNotVerified: {
    border: "1px solid #f44336",
  },
  verificationPopupTop: {
    top: "6rem",
  },
  verificationPopupBot: {
    bottom: "0",
  },
  verificationPopupContainer: {
    flex: "1",
    maxWidth: "100%",
    textAlign: "left",
    flexDirection: "column",
    position: "relative",
    "& img": {
      maxWidth: " 100%",
    },
  },
  verificationPopupContainerFlex: {
    display: "flex",
  },
  verificationPopupText: {
    fontSize: "1rem",
    textAlign: "left",
    marginLeft: "1rem",
    flex: "1",
  },
  verificationPopupClose: {
    position: "absolute",
    top: "0",
    right: "0",
    padding: ".5rem",
    cursor: "pointer",
    color: "grey",
  },
  verificationPopupRejection: {
    display: "flex",
    alignItems: "flex-end",
    marginLeft: "1rem",
  },
};

export const useStyles = makeStyles(styles);
