import React from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/postsStyle";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput";

const Delete = ({ deleteMessage, deleteInput, setDeleteInput, deleteReason, setDeleteReason }) => {
  const classes = useStyles();

  const disableButton = () => {
    return deleteReason.length === 0
  }

  return (
    <Card>
      <CardHeader color="warning">
        <h4 className={classes.cardTitleWhite}>Remove message</h4>
      </CardHeader>
      <CardBody>
        {deleteInput ? (
          <div>
            <div>
            <CustomInput
                labelText={"Reason for deletion"}
                id="delete-reason"
                formControlProps={{ fullWidth: true }}
                val={deleteReason}
                updateState={setDeleteReason}
              />
              <Button disabled={disableButton()} color="primary" onClick={() => deleteMessage(deleteReason)}>
                Confirm
              </Button>
              <Button color="warning" onClick={() => setDeleteInput(false)}>
                X
              </Button>
            </div>
          </div>
        ) : (
          <Button color="warning" onClick={() => setDeleteInput(true)}>
            Delete
          </Button>
        )}
      </CardBody>
    </Card>
  );
};

export default Delete;
