import React, { useState, useEffect, useRef } from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/bugStyle";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Icons } from "helpers/icons";
import { configDate } from "helpers";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button";
import Pagination from "components/Pagination/Pagination";
import { Loader } from "components/Loader/Loader";

import {getReportedBugs, updateBugStatus} from "services/endpoints/bugs";
import {useQuery, useQueryClient} from "react-query";

const BugReports = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [loading, setLoading] = useState(false);

  const scrollTarget = useRef(null);

  const classes = useStyles();
  const history = useHistory();
  const { addToast } = useToasts();
  const queryClient = useQueryClient()

  const { data, error } = useQuery(
    ["getAllBugs", currentPage],
    () => getReportedBugs(currentPage),
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (data) {
      setTotalPages(data.last_page);
      setLoading(false);
    } else {
      setLoading(true);
    }

    if (error) {
      setLoading(false);
      addToast("Something went wrong. Try reloading.", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 3000
      });
    }

    // eslint-disable-next-line
  }, [data, error]);

  const resolveBug = async (id,stat) => {
    const { error } = await updateBugStatus(id, { status: stat });
    if (!error) {
      addToast("Status updated", { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000 });
      queryClient.invalidateQueries("getAllBugs");
    }
    if (error) {
      addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000 });
    }
  };

  const renderBugsTable = (arr) => {
    const bugsData = arr.map((bug) => [
      `${bug.id}`,
      `${configDate(bug.created_at)}`,
      `${bug.subject}`,
      `${bug.body}`,
      `${bug.status.split("_").join(" ")}`,
      `${bug.user_profile.user.handle}`,
      <div style={{ float: "right" }}>
        {bug.status === "in_progress" &&
            <Button
                color="danger"
                title="Mark as Resolved"
                onClick={() =>
                    resolveBug(
                        bug.id,
                        "resolved"
                    )
                }
            >
              <Icons.Clear />
            </Button>
        }
        <Button
          color="info"
          title="Check bug"
          onClick={() => history.push(`problem/${bug.id}`)}
        >
          <Icons.RemoveRedEyeIcon />
        </Button>
      </div>,
    ]);

    return (
      <Table
        bugLink={true}
        tableHeaderColor="primary"
        tableHead={["ID", "Created on", "Subject", "Body", "Status", "Reported by", ""]}
        tableData={[...bugsData]}
      />
    );
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitlePrimary} ref={scrollTarget}>
              Bug Reports
            </h4>
            <p className={classes.cardCategoryPrimary}>
              List of all reported problems
            </p>
          </CardHeader>
          <CardBody>
            {data && renderBugsTable(data.data)}
            {loading && <Loader />}
          </CardBody>
          {data && data.data.length > 0 && (
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
              loading={loading}
              scrollTarget={scrollTarget.current}
            />
          )}
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default BugReports;
