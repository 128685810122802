import React from "react";
import Tabs from "../../components/CustomTabs/CustomTabs.js";
import TicketsReports from "./TicketsReports";
import Close from "@material-ui/icons/Close";
import FiberNewIcon from '@material-ui/icons/FiberNew';
export const OpenTicket = () => {

    return (
        <Tabs
            title="Open Tickets"
            headerColor="primary"
            tabs={[
                {
                    tabName: "Open Tickets",
                    tabIcon: FiberNewIcon,
                    tabContent: (
                       <TicketsReports filter={"in_progress"} />
                    ),
                },
            ]}
        />
    );
};

export const ClosedTickets = () => {

    return (
        <Tabs
            title="Closed tickets"
            headerColor="primary"
            tabs={[
                {
                    tabName: "Closed tickets",
                    tabIcon: Close,
                    tabContent: (
                        <TicketsReports filter={"resolved"} />
                    ),
                },
            ]}
        />
    );
};