import axios from 'axios'
import {
  getAccessToken,
  getExpirationDate,
  getRefreshTime,
  removeAccessToken
} from './storage'
import { timeSetup } from './timeSetup'

const apiUrl = process.env.REACT_APP_API_URL

const axiosInstance = axios.create ({
  baseURL : `${apiUrl}`,
})

axiosInstance.interceptors.request.use(
  async config => {
    const token = getAccessToken()
    config.headers = {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json'
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(response => {
  let now = new Date()
  let then = new Date(getExpirationDate())
  let refresh = new Date(getRefreshTime())
  if (now.getTime() > refresh.getTime() && now.getTime < then.getTime()) {
    const token = getAccessToken()
    axios({
      method: 'post',
      url: `${apiUrl}/api/auth/refresh`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(resp => {
      timeSetup(resp.data.expires_in)
    })
    .catch(err => console.log(err))
  } else if (now.getTime() > then.getTime()) {
    removeAccessToken()
  }

  return response
})

export default axiosInstance