import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Block from "@material-ui/icons/Block";
import BugReport from "@material-ui/icons/BugReport";
import Check from "@material-ui/icons/Check";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Clear from "@material-ui/icons/Clear";
import CropSquare from "@material-ui/icons/CropSquare";
import Dashboard from "@material-ui/icons/Dashboard";
import HighlightOff from "@material-ui/icons/HighlightOff";
import LinkedCamera from "@material-ui/icons/LinkedCamera";
import LinkedCameraIcon from "@material-ui/icons/LinkedCamera";
import Panorama from "@material-ui/icons/Panorama";
import People from "@material-ui/icons/People";
import PermMedia from "@material-ui/icons/PermMedia";
import Person from "@material-ui/icons/Person";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import ThumbDown from "@material-ui/icons/ThumbDown";
import ThumbUp from "@material-ui/icons/ThumbUp";
import Timeline from "@material-ui/icons/Timeline";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import ViewDay from "@material-ui/icons/ViewDay";
import ViewWeek from "@material-ui/icons/ViewWeek";
import Warning from "@material-ui/icons/Warning";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import LocalAtm from "@material-ui/icons/LocalAtm"
import FiberNewIcon from '@material-ui/icons/FiberNew';
import Close from '@material-ui/icons/Close';

export const Icons = {
  AccessTime,
  Accessibility,
  ArrowBack,
  ArrowDownward,
  ArrowUpward,
  Block,
  BugReport,
  Check,
  CheckCircleOutlineIcon,
  CropSquare,
  Clear,
  Dashboard,
  HighlightOff,
  LinkedCamera,
  LinkedCameraIcon,
  Panorama,
  People,
  PermMedia,
  Person,
  RemoveRedEyeIcon,
  ThumbDown,
  ThumbUp,
  Timeline,
  VerifiedUser,
  ViewDay,
  ViewWeek,
  Warning,
  RemoveCircleOutlineIcon,
  LocalAtm,
  FiberNewIcon,
  Close
};
