import React from "react"

import Card from "../Card/Card"
import CardHeader from "../Card/CardHeader"
import CardBody from "../Card/CardBody"

const Error = () => {
  return (
    <Card>
      <CardHeader color="warning">
        Something went wrong.
      </CardHeader>
      <CardBody>
        Try <span style={{color: "#00acc1", cursor: "pointer"}} onClick={() => window.location.reload()}>reloading</span> or relogging.
      </CardBody>
    </Card>
  )
}

export default Error