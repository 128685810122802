import React, { useState, useEffect } from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/postsStyle";
import { useQuery } from "react-query";
import { getSinglePostReports } from "services/endpoints/posts";
import { Icons } from "helpers/icons";

import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import Table from "components/Table/Table.js";
import CustomInput from "components/CustomInput/CustomInput";

const Reports = ({ id }) => {
  const [reports, setReports] = useState({
    data: [],
    page: 1,
    last_page: 1,
    handle: false,
    reason: "",
  });

  const classes = useStyles();

  const { data } = useQuery(
    ["singlePostReports", reports.page, id],
    () => getSinglePostReports(reports.page, id),
    () => getSinglePostReports(reports.page, id),
    { refetchOnWindowFocus: false }
  );

  const prevPage = () =>
    parseInt(reports.page) > 1 &&
    setReports({ ...reports, page: parseInt(reports.page) - 1 });
  const nextPage = () =>
    parseInt(reports.page) < reports.last_page &&
    setReports({ ...reports, page: parseInt(reports.page) + 1 });
  const customPage = (data) => setReports({ ...reports, page: data });

  useEffect(() => {
    if (data) {
      setReports({
        ...reports,
        data: data.data,
        last_page: data.last_page,
      });
    }
  }, [data, reports]);

  const renderReportsTable = () => {
    if (reports.data.length !== 0) {
      const reportsData = reports.data.map((report) => [
        `${report.reason.split("_").join(" ")}`,
        `${report.user_profile.user.name}`,
        `${report.user_profile.user.handle}`,
      ]);
      return (
        <Table
          tableHeaderColor="warning"
          tableHead={["Reason", "Reported by", "Username"]}
          tableData={[...reportsData]}
        />
      );
    }
  };

  return (
    <>
      {reports.data.length > 0 && (
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>
                <CardIcon color="warning">
                  <Icons.Warning />
                </CardIcon>
                This post has been reported!
              </h4>
            </CardHeader>
            <CardBody>{renderReportsTable()}</CardBody>
            <CardBody>
              {reports.last_page > 1 && (
                <>
                  <Button color="warning" onClick={() => prevPage()}>
                    Prev
                  </Button>
                  <Button color="warning" onClick={() => nextPage()}>
                    Next
                  </Button>
                  <CustomInput
                    labelText={`Page (out of ${reports.last_page})`}
                    id="page"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      placeholder: `Last page number: ${reports.last_page}`,
                    }}
                    val={`${reports.page}`}
                    updateState={customPage}
                  />
                </>
              )}
            </CardBody>
          </Card>
        </GridItem>
      )}
    </>
  );
};

export default Reports;
