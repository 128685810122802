import React from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/postsStyle";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

const Text = ({ text }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitlePrimary}>Text</h4>
      </CardHeader>
      <CardBody>
        <p>{text}</p>
      </CardBody>
    </Card>
  );
};

export default Text;
