import React, { useEffect, useState, useRef } from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/postsStyle";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button";
import Pagination from "components/Pagination/Pagination";
import { Loader } from "components/Loader/Loader";

import { configDate } from "helpers";

import { useQuery } from "react-query";
import { GetDeletedMedia } from "services/endpoints/messages";

const DeletedMediaTable = ({ api, header, type }) => {
    const [media, setMedia] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState();

    const scrollTarget = useRef(null);

    const classes = useStyles();
    const history = useHistory();
    const { addToast } = useToasts();

    const { data, error } = useQuery(
        [`${api}`, currentPage, api],
        () => GetDeletedMedia(currentPage, api),
        { refetchOnWindowFocus: false }
    );

    useEffect(() => {
        if (data) {
            setMedia(data);
            setTotalPages(data.last_page);
            setLoading(false);
        } else {
            setLoading(true);
        }

        if (error) {
            setLoading(false);
            addToast("Something went wrong. Try reloading.", {
                appearance: "error",
                autoDismiss: true, 
                autoDismissTimeout: 3000
            });
        }

        // eslint-disable-next-line
    }, [data, error]);

    const renderMediaTable = () => {
        if (media && media.data.length > 0) {
          if (type === "reports") {
                const mediaData = media.data.map((message) => [
                    `${configDate(message.created_at)}`,
                    `${message.reason.split("_").join(" ")}`,
                    `${message.user_profile?.user?.name || ""} - @${
                        message.user_profile?.user?.handle || ""
                    }`,
                    <Button
                    color="info"
                    title="Check message"
                    onClick={() => {history.push(`message/preview/${message.reportable_id}`)}}
                    >
                        <RemoveRedEyeIcon />
                    </Button>,
                ]);
                return (
                    <Table
                        tableHeaderColor="primary"
                        tableHead={[
                            "Reported on",
                            "Reason",
                            "Report submitted by",
                            "",
                        ]}
                        tableData={[...mediaData]}
                    />
                );
          } else if (type === 'deleted') {
            const mediaData = media.data.map((message) => [
                `${configDate(message.reportable.deleted_at)}`,
                `${message.reason.split("_").join(" ")}`,
                `${message.user_profile?.user?.name || ""} - @${
                    message.user_profile?.user?.handle || ""
                }`,
                <Button
                color="info"
                title="Check message"
                onClick={() => {history.push(`media/deleted/preview/${message.id}`)}}
                >
                    <RemoveRedEyeIcon />
                </Button>,
            ]);
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={[
                        "Reported on",
                        "Reason",
                        "Report submitted by",
                        "",
                    ]}
                    tableData={[...mediaData]}
                />
            );
          }
        } else {
            return <div>No deleted media</div>;
        }
    };

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitlePrimary} ref={scrollTarget}>
                            {header}
                        </h4>
                    </CardHeader>

                    <CardBody>
                        {media && renderMediaTable()}
                        {loading && <Loader />}
                    </CardBody>

                    {media && (
                        <Pagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPages={totalPages}
                            loading={loading}
                            scrollTarget={scrollTarget.current}
                        />
                    )}
                </Card>
            </GridItem>
        </GridContainer>
    );
};

export default DeletedMediaTable;
