import { makeStyles } from "@material-ui/core/styles";

const styles = {
  cardTitlePrimary: {
    color: "#ff4443",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  postImage: {
    maxHeight: "200px",
    maxWidth: '100%',
    marginRight: "10px",
    "&:hover": {
      cursor: "zoom-in",
    },
  },
  cardTitleWhite: {
    color: "#fff",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  userProfileLink: {
    color: "#00acc1",
  },
};

export const useStyles = makeStyles(styles);
