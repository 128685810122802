import React, { useState, useEffect, useRef } from "react";
import { useToasts } from "react-toast-notifications";
import { configDate } from "helpers";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import Pagination from "components/Pagination/Pagination";

import { getLoginsData } from "services/endpoints/users";
import { useQuery } from "react-query";

const Logins = ({ id }) => {
  const [loginsData, setLoginsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const scrollTarget = useRef(null);

  const { addToast } = useToasts();

  const { data, error } = useQuery(
    ["userslogins", id, currentPage],
    () => getLoginsData(id, currentPage),
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (data) {
      setLoginsData(data.data);
      setCurrentPage(data.current_page);
      setTotalPages(data.last_page);
      setLoading(false);
    } else {
      setLoading(true);
    }
    if (error) {
      addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000});
      setLoading(false);
    }
  }, [data, error, addToast]);

  const configTable = () => {
    const head = ["ID", "Logged on", "IP", "Location"];
    const body = loginsData.map((item) => [
      `${item.id || ""}`,
      `${configDate(item.created_at) || ""}`,
      `${item.ip_address}`,
      `${item.country_string}`,
    ]);

    return (
      <Table tableHeaderColor="primary" tableHead={head} tableData={body} />
    );
  };

  return (
    <Card>
      <CardHeader color="primary">
        <span ref={scrollTarget}>Logins</span>
      </CardHeader>
      <CardBody>
        {loginsData.length > 0 ? configTable() : "User hasn't logged in yet"}
      </CardBody>
      {loginsData.length > 0 && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          loading={loading}
          scrollTarget={scrollTarget.current}
        />
      )}
    </Card>
  );
};

export default Logins;
