import React from 'react'
import { removeAccessToken } from '../../api/storage'
import { useHistory } from 'react-router-dom'

import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import Card from '../../components/Card/Card.js'
import CardHeader from '../../components/Card/CardHeader.js'
import CardBody from '../../components/Card/CardBody.js'
import Button from '../../components/CustomButtons/Button.js'

const Logout = () => {
  const history = useHistory()

  const confirm = () => {
    removeAccessToken()
    history.push('/')
  }

  const cancel = () => window.history.back()

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            Log out?
          </CardHeader>
          <CardBody>
            <Button color="success" onClick={() => confirm()}>Confirm</Button>
            <Button color="primary" onClick={() => cancel()}>Cancel</Button>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default Logout