import axiosInstance from "api/axiosInstance";

// RESTRICTED WORDS TABLE
export const getRestrictedWords = async (page = 1) => {
    const { data } = await axiosInstance({
        method: "get",
        url: `/api/admin/restricted-words?page=${page}`,
    });

    return data;
};

// POST NEW RESTRICTED WORD
export const postWord = async (word) => {
    const { data } = await axiosInstance({
        method: "post",
        url: `/api/admin/restricted-words `,
        data: { word: word },
    });
    return data;
};

//UPDATE RESTRICTED WORD
export const updateWord = async (newWord,wordId) => {
    const { data } = await axiosInstance({
        method: "put",
        url: `/api/admin/restricted-words/${wordId}`,
        data: { word: newWord },
    });
    return data;
};

//DELETE RESTRICTED WORD
export const deleteWord = async (wordId) => {
    const { data } = await axiosInstance({
        method: "delete",
        url: `/api/admin/restricted-words/${wordId}`,
    });
    return data;
};