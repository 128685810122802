import React from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";

import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {
  const history = useHistory()

  const classes = useStyles();
  const [openProfile, setOpenProfile] = React.useState(null);
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  return (
    <div>
      <div className={classes.manager}>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <Divider light />
                    <MenuItem
                      onClick={() => {
                        history.push(`/admin/logout`)
                        handleCloseProfile()
                      }}
                      className={classes.dropdownItem}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
}
