import React from "react";
import Tabs from "../../components/CustomTabs/CustomTabs.js";
import MessagesTable from "./MessagesTable";

import { Icons } from "helpers/icons";
import MediaTable from "./MediaTable.js";
import DeletedMediaTable from "./DeletedMediaTable.js";

export const ReportedMessages = () => {
    return (
        <Tabs
            title="Messages:"
            headerColor="rose"
            tabs={[
                {
                    tabName: "Reports",
                    tabIcon: Icons.Warning,
                    tabContent: (
                        <MessagesTable
                            api="admin/reports/messages"
                            header="Reported messages"
                            type="reports"
                        />
                    ),
                },
            ]}
        />
    );
};

export const ReportedMedia = () => {
    return (
        <Tabs
            title="Media:"
            headerColor="rose"
            tabs={[
                {
                    tabName: "Reports",
                    tabIcon: Icons.Warning,
                    tabContent: (
                        <MediaTable
                            api="admin/reports/media"
                            header="Reported media"
                            type="reports"
                        />
                    ),
                },
            ]}
        />
    );
};

export const DeletedMedias = () => {
    return (
        <Tabs
            title="Media:"
            headerColor="rose"
            tabs={[
                {
                    tabName: "Deleted",
                    tabIcon: Icons.Warning,
                    tabContent: (
                        <DeletedMediaTable
                            api="admin/media/get/deleted-reports"
                            header="Deleted media"
                            type="deleted"
                        />
                    ),
                },
            ]}
        />
    );
};