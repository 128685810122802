import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

import { useHistory } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const history = useHistory();

  const classes = useStyles();
  const {
    tableHead,
    tableData,
    tableHeaderColor,
    profileLink = false,
    postLink = false,
    transactionLink = false,
    bugLink = false,
  } = props;

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
            return (
              <TableRow key={key} className={classes.tableBodyRow}>
                {prop.map((item, key) => {
                  return (
                    <TableCell
                      className={`${classes.tableCell} ${
                        (key === 1 &&
                          profileLink &&
                          classes.tableUsernameLink) ||
                        (key === 3 && postLink && classes.tableUsernameLink) ||
                        (key === 8 &&
                          transactionLink &&
                          classes.tableUsernameLink) ||
                        (key === 2 && bugLink && classes.tableUsernameLink)
                      }`}
                      key={key}
                      onClick={() => {
                        if (key === 1 && profileLink) {
                          history.push(`profile/${prop[0]}`);
                        }
                        if (key === 3 && postLink) {
                          history.push(`preview/${prop[0]}`);
                        }
                        if (key === 8 && transactionLink) {
                          history.push(`/admin/profile/${prop[7]}`);
                        }
                        if (key === 2 && bugLink) {
                          history.push(`/admin/problem/${prop[0]}`);
                        }
                      }}
                    >
                      {item}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  // tableHead: PropTypes.arrayOf(PropTypes.string),
  // tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
