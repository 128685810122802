import React, { useState } from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/usersStyle";
import { configDateId, calcAge } from "helpers";
import { useToasts } from "react-toast-notifications";
import axiosInstance from "api/axiosInstance";
import { Icons } from "helpers/icons";
import CustomInput from "components/CustomInput/CustomInput";
import { useHistory } from 'react-router-dom'
import Button from "components/CustomButtons/Button";

import FaceImg from "assets/img/face_not_uploaded.png";
import IDImg from "assets/img/id_not_uploaded.png";
const VerificationPopup = ({
  profile,
  name,
  approveProfile,
  rejectProfile,
  setpopup,
  email,
  emailVerified,
  handle,
  banned,
  id,
  refetchUsers
}) => {
  const [rejectionActive, setRejectionActive] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");

  const classes = useStyles();
  const { addToast } = useToasts();

  const updateRejectionReason = (data) => setRejectionReason(data);
  const [ban,setBan] = useState(banned)
  const history = useHistory()

  const banProfile = (action) => {
    axiosInstance({
      method: "post",
      url: `/api/admin/users/${id}/ban${!action ? "?action=0" : ""}`,
    })
        .then((resp) => {
            if(action) {
              history.push('/admin/banned-users')
            };
            setBan(!action ? 0 : 1);
            addToast(`User ${!action ? "unbanned" : "banned"} successfully`, { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000});
         })
        .catch((err) =>
            addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000})
        );
  };

  return (
    <>
      <div className={classes.verificationPopupContainer}>
        <img
          alt="Face"
          src={profile?.face_image_url || FaceImg}
          onClick={() => {
            profile?.face_image_url &&
              window.open(profile?.face_image_url, "_blank");
          }}
          style={{ cursor: profile?.face_image_url ? "zoom-in" : "default" }}
        />
        <br />
        <img
          alt="ID"
          src={profile?.id_image_url || IDImg}
          onClick={() => {
            profile?.id_image_url && window.open(profile?.id_image_url, "_blank");
          }}
          style={{ cursor: profile?.id_image_url ? "zoom-in" : "default" }}
        />
      </div>
      <div
        className={`${classes.verificationPopupContainer} ${classes.verificationPopupContainerFlex}`}
      >
        <p className={classes.verificationPopupText}>
          Status:{" "}
          <b>{profile?.face_verified_at ? "Verified" : "Not verified"}</b>
          <br />
          {name ? `Name - ${name}` : ""}
          <br />
          {profile?.birthday
            ? `DOB - ${configDateId(profile?.birthday)} (Age: ${calcAge(
                profile?.birthday
              )})`
            : ""}
          <br />
          {handle ? `Username - ${handle}` : ""}
          <br />
          {email ? `Email - ${email}` : ""}{" "}
          <span
            title={emailVerified ? "Email verified" : "Email is not verified"}
          >
            {emailVerified ? "✓" : "✗"}
          </span>
        </p>
        <div>
          <div style={{ textAlign: "right" }}>
            <Button color="warning" onClick={() => banProfile(ban === 1 ? false : true)}>
              {ban === 1 ? "Unban" : "Ban"} Profile
            </Button>
            {!profile?.face_verified_at && (
              <Button
                color="success"
                title="Approve User"
                onClick={approveProfile}
              >
                <Icons.Check />
              </Button>
            )}
            <Button
              color="primary"
              title="Reject User"
              onClick={() => setRejectionActive(!rejectionActive)}
            >
              <Icons.Clear />
            </Button>
            <Icons.HighlightOff
              className={classes.verificationPopupClose}
              onClick={() => {
                setpopup({
                  active: null,
                  name: "",
                  profile: {},
                });
                setRejectionReason("");
                setRejectionActive(false);
              }}
            />
          </div>
          {rejectionActive && (
            <div className={classes.verificationPopupRejection}>
              <CustomInput
                labelText={
                  profile?.face_verified_at
                    ? "Reason for removing verification"
                    : "Reason for rejecting"
                }
                id="rejection-reason"
                formControlProps={{ fullWidth: true }}
                val={`${rejectionReason}`}
                updateState={updateRejectionReason}
              />
              <Button
                style={{ height: "44px" }}
                color="primary"
                title="Reject User"
                onClick={() => {
                  if (rejectionReason.length > 0) {
                    rejectProfile(rejectionReason);
                  } else {
                    addToast("You neeed to write a reason", {
                      appearance: "warning",
                    });
                  }
                }}
              >
                Confirm
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default VerificationPopup;
