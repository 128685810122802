import axiosInstance from "api/axiosInstance";

export const getFeature = async (page = 1) => {
    const { data } = await axiosInstance({
        method: "get",
        url: `/api/admin/problem/reports?type=feature&?page=${page}`,
    });

    return data;
};

export const getFeatureSingle = async (id) => {
    const { data } = await axiosInstance({
        method: "get",
        url: `/api/admin/problem/reports/${id}`,
    });

    return data;
};

export const commentOnFeature = async (id, comm) => {
    const { data } = await axiosInstance({
        method: "post",
        url: `/api/admin/problem/reports/${id}/comments`,
        data: comm,
    });

    return data;
};

export const updateFeatureStatus = async (id, stat) => {
    const { data } = await axiosInstance({
        method: "put",
        url: `/api/admin/problem/reports/${id}`,
        data: stat,
    });

    return data;
};