import React, { useEffect, useState } from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/bugStyle";
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Icons } from "helpers/icons";
import { configDate } from "helpers";

import { useQuery, useQueryClient } from "react-query";
import {
  getReportedBug,
  commentOnBug,
  updateBugStatus,
} from "services/endpoints/bugs";


import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Images from "./Images";
import Videos from "./Videos";

const BugPage = () => {
  const [newComment, setNewComment] = useState("");

  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const { data, error } = useQuery(
    ["getBugData", id],
    () => getReportedBug(id),
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (error) addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000 });

    // eslint-disable-next-line
  }, [error]);

  const postNewComment = async () => {
    if (newComment !== "") {
      const { error } = await commentOnBug(id, { body: newComment });
      if (!error) {
        addToast("Comment added successfully", { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000 });
        queryClient.invalidateQueries("getBugData");
        setNewComment("");
      }
      if (error) {
        addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000 });
      }
    }
  };

  const resolveBug = async (stat) => {
    const { error } = await updateBugStatus(id, { status: stat });
    if (!error) {
      addToast("Status updated", { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000 });
      queryClient.invalidateQueries("getBugData");
    }
    if (error) {
      addToast("Something went wrong", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000 });
    }
  };

  const updateNewCommentText = (data) => setNewComment(data);

  const returnImagesArray = () => {
      if(data && data.images !== undefined) {
        return JSON.parse(data.images)
      }
  }

  const returnVideosArray = () => {
    if(data && data.videos !== undefined) {
      return JSON.parse(data.videos)
    }
  }
  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Button color="primary" onClick={() => history.goBack()}>
          <Icons.ArrowBack /> Return
        </Button>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">Problem</CardHeader>
          <CardBody>
            <p>
              <b>Problem:</b> {data?.body || ""}
            </p>
            <p>
              <b>Status:</b> {data?.status}
            </p>
            {data?.status === "in_progress" ?
                <Button
                    color="success"
                    style={{ float: "right" }}
                    onClick={() =>
                        resolveBug(
                            data?.status === "in_progress" ? "resolved" : "in_progress"
                        )
                    }
                >
                  {data?.status === "in_progress"
                      ? "Mark as Resolved"
                      : "Mark as In progress"}
                </Button> : ""
            }
          </CardBody>
        </Card>
      </GridItem>
      {returnImagesArray() ?
        <GridItem xs={12} sm={12} md={12} >
          <Images images={returnImagesArray()} />
        </GridItem> : ""
      }
      {returnVideosArray() ?
          <GridItem xs={12} sm={12} md={12} >
            <Videos videos={returnVideosArray()} />
          </GridItem> : ""
      }
      <GridItem xs={12} sm={12} md={12} >
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitlePrimary}>Comments</h4>
          </CardHeader>
          <CardBody>
            {data &&
              data.comments.map((e) => (
                <div
                  className={e.is_admin_response && classes.bugBubbleAdminWrap}
                  key={e.id}
                >
                  <div
                      className={
                        e.is_admin_response
                            ? classes.bugBubbleAdmin
                            : classes.bugBubbleGuest
                      }
                  >
                    <span>{e.is_admin_response ? "Fluffa" : e.user_profile.user.handle}</span>
                    <p>{e.body}</p>
                    <span>{configDate(e.created_at)}</span>
                  </div>
                </div>
              ))}

            <CustomInput
              labelText={`Write a response`}
              id="newcomment"
              formControlProps={{ fullWidth: true }}
              val={`${newComment}`}
              updateState={updateNewCommentText}
            />
            <Button color="success" onClick={postNewComment}>
              Submit
            </Button>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default BugPage;
