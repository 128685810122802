import axiosInstance from "api/axiosInstance";

export const getReportedBugs = async (page = 1) => {
  const { data } = await axiosInstance({
    method: "get",
    url: `/api/admin/problem/reports?page=${page}`,
  });

  return data;
};

export const getReportedBug = async (id) => {
  const { data } = await axiosInstance({
    method: "get",
    url: `/api/admin/problem/reports/${id}`,
  });

  return data;
};

export const commentOnBug = async (id, comm) => {
  const { data } = await axiosInstance({
    method: "post",
    url: `/api/admin/problem/reports/${id}/comments`,
    data: comm,
  });

  return data;
};

export const updateBugStatus = async (id, stat) => {
  const { data } = await axiosInstance({
    method: "put",
    url: `/api/admin/problem/reports/${id}`,
    data: stat,
  });

  return data;
};
