import React, { useState } from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/postsStyle";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// import { deleteSingleAttachment } from "services/endpoints/messages";
// import { useToasts } from "react-toast-notifications";

const Attachments = (props) => {
  const classes = useStyles();

  const [attachments, setAttachments] = useState([]);
  // const { addToast } = useToasts();

  useState(() => {
    setAttachments(props.attachments);
  }, []);

  // const removeAttachment = async (id) => {
  //   try{
  //     const { error } = await deleteSingleAttachment(id);
  //     setAttachments((prevState) => {
  //       return prevState.filter((attachment) => {
  //         return attachment.id != id;
  //       });
  //     });
  //
  //     addToast("Message deleted successfully", { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000});
  //   }catch (e) {
  //     addToast("This message was already deleted!", { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000});
  //   }
  // }

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitlePrimary}>Attachments</h4>
      </CardHeader>
      <CardBody >
        {attachments?.map((attachment => {
            return (
            <div>
              <img src={attachment.url} width='100%' alt={`attachment-${attachment.id}`}/>
            </div>);
        }))}
      </CardBody>
    </Card>
  );
};

export default Attachments;