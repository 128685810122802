import React from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/postsStyle";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput";

const Delete = ({ deletePost, deleteInput, setDeleteInput, deleteReason, setDeleteReason }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader color="warning">
        <h4 className={classes.cardTitleWhite}>Remove post</h4>
      </CardHeader>
      <CardBody>
        {deleteInput ? (
          <>
            {setDeleteReason && <CustomInput
                labelText={"Reason for deletion"}
                id="delete-reason"
                formControlProps={{ fullWidth: true }}
                val={deleteReason}
                updateState={setDeleteReason}
            />}
            <Button color="primary" onClick={() => deletePost()}>
              Confirm
            </Button>
            <Button color="warning" onClick={() => setDeleteInput(false)}>
              X
            </Button>
          </>
        ) : (
          <Button color="warning" onClick={() => setDeleteInput(true)}>
            Delete
          </Button>
        )}
      </CardBody>
    </Card>
  );
};

export default Delete;
