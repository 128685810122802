import React from "react";
import { useStyles } from "assets/jss/material-dashboard-react/views/postsStyle";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import VideoPlayer from 'react-video-js-player';

const Videos = ({ videos }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitlePrimary}>Videos</h4>
      </CardHeader>
      <CardBody>
        {videos.map((e) => (
            <VideoPlayer
                className={classes.postImage}
                controls={true}
                src={e.url}
                poster={e.thumb_url}
                width="600"
                height="600"
            />
        ))}
      </CardBody>
    </Card>
  );
};

export default Videos;
